import Sketch from "../../../../src/components/sketch";
import sketchSarsa from "../../../../src/scripts/sSarsa/sSarsa";
import sketchQ from "../../../../src/scripts/sQlearningSarsa/sQlearningSarsa";
import { Link } from 'gatsby';
import Layout from "../../../../src/components/blogLayout";
import DateConverter from "../../../../src/components/dateConverter";
import { SideNote, MarginNote } from "../../../../src/components/sideNote";
import { KatexBlock, KatexInline } from "../../../../src/components/Katex";
import Commento from "../../../../src/components/commento";
import NewThought from "../../../../src/components/newThought";
import sketchPolicy from "../../../../src/scripts/sPolicy/sPolicy";
import sketchValue from "../../../../src/scripts/sValue/sValue";
import * as React from 'react';
export default {
  Sketch,
  sketchSarsa,
  sketchQ,
  Link,
  Layout,
  DateConverter,
  SideNote,
  MarginNote,
  KatexBlock,
  KatexInline,
  Commento,
  NewThought,
  sketchPolicy,
  sketchValue,
  React
};