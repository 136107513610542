import Sketch from "../../../../src/components/sketch";
import sketchQ from "../../../../src/scripts/sQlearning/sQlearning";
import sketchQExp from "../../../../src/scripts/sQlearningExp/sQlearningExp";
import { Link } from 'gatsby';
import Layout from "../../../../src/components/blogLayout";
import DateConverter from "../../../../src/components/dateConverter";
import { SideNote, MarginNote } from "../../../../src/components/sideNote";
import { KatexBlock, KatexInline } from "../../../../src/components/Katex";
import Commento from "../../../../src/components/commento";
import * as React from 'react';
export default {
  Sketch,
  sketchQ,
  sketchQExp,
  Link,
  Layout,
  DateConverter,
  SideNote,
  MarginNote,
  KatexBlock,
  KatexInline,
  Commento,
  React
};