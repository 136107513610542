/* eslint-disable one-var */
/* eslint-disable prettier/prettier */
/* eslint-disable import/no-named-as-default */
/* eslint no-param-reassign: "off", no-use-before-define: "off" */
import Gridworld from './gridworldExtended';
import LearningCurve from './learningCurve';
import CurrentValues from './currentValues';
// import Q from './algoQ';
// import "katex/dist/katex.min.css"

class HumanAgent {
    constructor(p, d, sharedParams, gw, lc) {
        this.p = p;
        this.d = d;
        this.sharedParams = sharedParams;
        this.xPos = this.d.xAgent;
        this.yPos = this.d.yAgent;
        this.gw = gw;
        this.lc = lc;

        //
        //  MANUAL
        //
        const marginWidth = d.hMargin;
        const innerBoxWidth = this.d.manualWidth - 22 - marginWidth - 1;
        const textWidth = (2 / 3) * innerBoxWidth;
        const controlWidth = innerBoxWidth - textWidth - 1;
        const buttonWidth = controlWidth / 3;
        const buttonHeight = controlWidth / 3;

        // console.log("textWidth", textWidth);
        // console.log("buttonWidth", buttonWidth);

        this.manualContainer = this.p
            .createDiv()
            .position(this.d.xManual, this.d.yManual)
            .class('manualContainer')
            .style('width', `${this.d.manualWidth}px`);
        this.manualBox = this.p
            .createDiv()
            .parent(this.manualContainer)
            .style('width', `${textWidth}px`)
            .style('vertical-align', 'middle')
            .style('display', 'inline-block');

        this.p
            .createSpan('Use your arrow keys or the buttons on the right to control the agent. ')
            .parent(this.manualBox);

        this.p
            .createDiv()
            .style('width', `${marginWidth}px`)
            .style('vertical-align', 'middle')
            .parent(this.manualContainer)
            .style('display', 'inline-block');
        this.controlButtonBox = this.p
            .createDiv()
            .parent(this.manualContainer)
            .style('width', `${controlWidth}px`)
            .style('vertical-align', 'middle')
            .style('display', 'inline-block');
        this.buttonInnerBox = this.p.createDiv().parent(this.controlButtonBox);
        this.p
            .createDiv()
            .style('display', 'inline-block')
            .parent(this.buttonInnerBox)
            .style('width', `${buttonWidth}px`);
        this.buttonUp = this.p
            .createButton('keyboard_arrow_up')
            .style('display', 'inline-block')
            .parent(this.buttonInnerBox)
            .class('inlineMaterial')
            .size(buttonWidth, buttonHeight);
        this.p
            .createDiv()
            .style('display', 'inline-block')
            .parent(this.buttonInnerBox)
            .style('width', `${buttonWidth}px`);
        // this.p.createElement('br').parent(this.buttonInnerBox);
        this.buttonLeft = this.p
            .createButton('keyboard_arrow_left')
            .style('display', 'inline-block')
            .parent(this.buttonInnerBox)
            .class('inlineMaterial')
            .size(buttonWidth, buttonHeight);
        this.p
            .createDiv()
            .style('display', 'inline-block')
            .parent(this.buttonInnerBox)
            .style('width', `${buttonWidth}px`);
        this.buttonRight = this.p
            .createButton('keyboard_arrow_right')
            .style('display', 'inline-block')
            .parent(this.buttonInnerBox)
            .class('inlineMaterial')
            .size(buttonWidth, buttonHeight);
        // this.p.createElement('br').parent(this.buttonInnerBox);
        this.p
            .createDiv()
            .style('display', 'inline-block')
            .parent(this.buttonInnerBox)
            .style('width', `${buttonWidth}px`);
        this.buttonDown = this.p
            .createButton('keyboard_arrow_down')
            .style('display', 'inline-block')
            .parent(this.buttonInnerBox)
            .class('inlineMaterial')
            .size(buttonWidth, buttonHeight);
        this.p
            .createDiv()
            .style('display', 'inline-block')
            .parent(this.buttonInnerBox)
            .style('width', `${buttonWidth}px`);
    }
}

function convertRemToPixels(rem) {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

export default function sketch(p) {
    const kat = require('katex');
    window.addEventListener(
        'keydown',
        function(e) {
            if (['ArrowLeft', 'ArrowDown', 'ArrowUp', 'ArrowRight'].indexOf(e.code) > -1) {
                e.preventDefault();
            }
        },
        false
    );

    // DESIGN DICT d
    const d = {
        // Basic dimensions
        sketchWidth: 800,
        sketchHeight: 450,
        kat: kat
    };

    // Base lengths, layout
    d.margin = d.sketchWidth / 30;
    d.hMargin = d.margin / 2;
    d.hSketchWidthMinusMargin = d.hSketchWidth - d.margin;
    d.hSketchWidth = d.sketchWidth / 2;
    d.hSketchHeight = d.sketchHeight / 2;
    d.tSketchWidth = d.sketchWidth / 3;
    d.tSketchHeight = d.sketchHeight / 3;

    // Dimensions of main components
    // d.algoWidth = d.hSketchWidth; //  - 1 * d.margin
    // d.algoHeight = d.tSketchWidth - 1 * d.margin;
    d.gwWidth = d.hSketchWidth;
    d.gwHeight = d.hSketchWidth;
    d.lcWidth = d.hSketchWidth - 2.2 * d.margin;
    d.lcHeight = d.hSketchHeight; // - 0.2 * d.margin
    d.cvWidth = d.lcWidth;
    d.algoParamWidth = d.lcWidth;
    d.manualWidth = d.hSketchWidth - 2 * d.margin;

    // Gridworld
    d.numActions = 4;
    d.numColumns = 5;
    d.numRows = 5;
    d.numStates = d.numColumns * d.numRows;
    d.pcPositions = [23];
    d.mrPositions = [8, 10];
    d.cellSize = d.gwWidth / d.numColumns;
    d.innerCellMargin = d.cellSize / 10;
    d.emojiOffSetY = d.cellSize / 22;
    d.lcMaxReturn = 10;
    d.lcMinReturn = -10;
    d.showRewards = true;
    d.showStateLabels = true;

    // Positions of main elements
    // d.xAgent = 0;
    // d.yAgent = 0;
    // d.xAgentAlgo = d.sketchWidth * 2 / 3 + 2 * d.margin;
    // d.yAgentAlgo = d.lcHeight + 2 * d.margin;
    d.xGw = 1;
    d.yGw = 1;
    d.xGwSet = d.xGw + 1.2 * d.cellSize; //  + d.hMargin
    d.yGwSet = d.yGw + d.gwHeight + d.hMargin / 2;
    d.xLc = d.hSketchWidth + 2 * d.margin;
    d.yLc = d.tSketchHeight - 0.8 * d.margin;
    d.xCv = d.hSketchWidth + 2 * d.margin;
    d.yCv = d.yLc + d.lcHeight + 0.8 * d.margin;
    d.xManual = d.hSketchWidth + 1.8 * d.margin;
    d.yManual = 1;

    // d.xCv = 0;
    // d.yCv = d.gwHeight - 3 / 4 *  d.margin;

    // Colors
    // d.bg = p.color(248);
    d.bg = p.color(245);
    d.minColor = p.color(0, 102, 153); // TODO: import from variables.scss
    d.maxColor = p.color(204, 102, 0);

    // Text sizes & related  TODO: check with scss
    d.emojiSize = 40;
    d.valueSize = 11;
    d.rewardSize = 13;
    d.rewardOffSetX = (1 / 3) * d.cellSize;
    d.rewardOffSetY = (3 / 8) * d.cellSize;
    d.policyArrowSize = (d.cellSize * 4) / 10;
    d.arrowHeadSize = 4;
    d.axisTickSize = d.lcWidth / 60; // d.margin / 7;
    d.lcLeftPadding = d.axisTickSize * 8; // Of the axis lines... this is the space for axis labels.
    d.lcTopPadding = 52; // 1.5 * d.margin;
    d.lcBottomPadding = 36; // 1.5 * d.margin;
    d.lcHeightMinusPadding = d.lcHeight - d.lcTopPadding - d.lcBottomPadding;
    d.axisLabelSize = 11;
    d.axisTickLabelSize = -1;
    d.lcCircleWidth = 5;
    d.returnLabel = 'Return';

    // Time
    d.maxFramesTillStep = 50;
    d.fadeFrames = 40;
    d.framesPlay = 5; // Frames of agent movement when playing continuously (has to be less than the number of lines in pseudo code until next arrives at that point)
    d.framesStill = 10; // Frames of agent movement when playing line by line.
    // d.framesLearn = 28; // Frames of arrow/reward movement when playing continuously.
    // d.framesLearnStill = 28; // Frames of arrow/reward movement when playing playing line by line.
    // d.afterLandingFrames = 7; // How long should reward and arrow stay on their target after arriving?

    // Titles
    d.lcTitle = 'PERFORMANCE'; //  "LEARNING CURVE";
    d.algoTitle = 'Q-LEARNING'; // PSEUDO CODE of
    d.algoParamTitle = 'ALGORITHM PARAMETERS';
    d.currentVariablesTitle = ' MDP VARIABLE VALUES';

    // Init main components
    let agent;
    let gw;
    let lc;
    let cv;

    let currentReturn = 0;

    let framesTillStep;
    let frameSinceLast = 0;

    // Profiling
    let currentFr = 0;
    // let currentDLUL = 0;

    // These game-flow parameters are shared across many components.
    const sharedParams = {
        play: false,
        playOnce: false
    };

    // Place the sketch into the left margin if window is wide enough
    let cnv = p.createCanvas(0, 0);
    const sketchContainer = cnv.parent();
    sketchContainer.id = 'sketchContainerPancakesMDP';
    sketchContainer.classList.add('sketchContainer');
    const p5parent = p.select('#sketchContainerPancakesMDP');
    p5parent.style('width', `${d.sketchWidth}px`);
    p5parent.style('position', 'relative');
    // const windowMinusPadding = window.innerWidth - 36;
    // if (windowMinusPadding >= d.sketchWidth) {
    //     const articlePadding = 0.125 * (windowMinusPadding);
    //     const articleWidth = Math.min(1400, 0.875 * (windowMinusPadding));
    //     const articleLeftMargin = (windowMinusPadding - articleWidth) / 2;
    //     const parentLeft = articlePadding + articleLeftMargin + 18;
    //     const lM = `${(Math.max(window.innerWidth - d.sketchWidth, 0) / 2 - parentLeft)}px`;
    //     p5parent.style("margin-left", lM);
    // }

    p.setup = () => {
        cnv = p.createCanvas(d.sketchWidth, d.sketchHeight).class('algocanvas'); // .parent(cnvContainer)
        p.clear();
        p.smooth();
        p.textFont('Lato');
        gw = new Gridworld(p, d, sharedParams, undefined, 'human');
        gw.reset(0);
        lc = new LearningCurve(p, d, sharedParams, true, false);
        agent = new HumanAgent(p, d, sharedParams, gw, lc);
        cv = new CurrentValues(p, d, sharedParams, gw, lc);
        connectAgent();
        fillInCurrentValues();
    };

    p.draw = () => {
        drawBackgroundAndGrid(false); // false
        // framesTillStep = d.maxFramesTillStep / agent.speedSlider.value();
        // d.framesPlay = Math.min(Math.floor(5 * framesTillStep), 30);
        // frameSinceLast += 1
        // if ((sharedParams.play && frameSinceLast >= framesTillStep)|| sharedParams.playOnce) {
        //     frameSinceLast = 0
        //     sharedParams.playOnce = false;
        //     agent.step();
        // }
        // fillInCurrentValues();
        lc.drawReturnPerEpisode();
        // agent.processDelayedQUpdates();
        gw.drawQagent(undefined, false);
        // performanceProfiling();
        if (gw.isTerminal && gw.agentMovingList.length === 0) {
            // Agent arrived at terminal state. Reset!
            lc.pushReturn(currentReturn);
            gw.reset(0);
            currentReturn = 0;
            lc.updateReturn(currentReturn);
        }
    };

    function connectAgent() {
        agent.buttonUp.mousePressed(() => {
            const action = 0;
            makeStep(action);
        });
        agent.buttonRight.mousePressed(() => {
            const action = 1;
            makeStep(action);
        });
        agent.buttonDown.mousePressed(() => {
            const action = 2;
            makeStep(action);
        });
        agent.buttonLeft.mousePressed(() => {
            const action = 3;
            makeStep(action);
        });
    }

    p.keyPressed = () => {
        if (!gw.isTerminal) {
            let action;
            let isValidKey = true;
            if (p.keyCode === p.LEFT_ARROW) {
                action = 3;
            } else if (p.keyCode === p.RIGHT_ARROW) {
                action = 1;
            } else if (p.keyCode === p.UP_ARROW) {
                action = 0;
            } else if (p.keyCode === p.DOWN_ARROW) {
                action = 2;
            } else {
                isValidKey = false;
            }
            if (isValidKey) {
                makeStep(action);
            }
        }
        return false;
    };

    function makeStep(action) {
        agent.currentA = action;
        const reward = gw.step(action);
        currentReturn += reward;
        lc.updateReturn(currentReturn);
        agent.currentR = reward;
        fillInCurrentValues();
    }

    function fillInCurrentValues() {
        kat.render(
            typeof agent.currentR === 'undefined' ? 'R = ' : `R = ${agent.currentR}`,
            cv.currentRLabel.elt
        );
        kat.render(
            typeof gw.currentState === 'undefined' ? 'S = ' : `S = s_{${gw.currentState}}`,
            cv.currentSLabel.elt
        ); //\\text{current state }
        // kat.render(typeof(agent.nextS) === "undefined" ? "S' = " : `S' = s_{${agent.nextS}}`, cv.nextSLabel.elt);
        kat.render(
            typeof agent.currentA === 'undefined'
                ? 'A = '
                : `A = ${actionToCardinalDir(agent.currentA)}`,
            cv.currentALabel.elt
        );
        // kat.render((typeof(agent.currentA) === "undefined" || typeof(agent.currentS) === "undefined") ? "Q(S, A) = " : "Q(S, A) = " + Math.round(agent.qTable[agent.currentS][agent.currentA] * 100) / 100, cv.currentQLabel.elt);
        // kat.render(typeof(agent.nextS) === "undefined" ? "max_{a}Q(S', a) = " : "max_{a}Q(S', a) = " + Math.round(Math.max(...agent.qTable[agent.nextS]) * 100) / 100, cv.nextQLabel.elt);
    }

    function actionToCardinalDir(a) {
        let cardDir;
        // if (a === 0) {
        //     cardDir = 'north';
        // } else if (a === 1) {
        //     cardDir = 'east';
        // } else if (a === 2) {
        //     cardDir = 'south';
        // } else if (a === 3) {
        //     cardDir = 'west';
        // }
        // return `\\text{${cardDir}}`;
        if (a === 0) {
            cardDir = 'a_n';
        } else if (a === 1) {
            cardDir = 'a_e';
        } else if (a === 2) {
            cardDir = 'a_s';
        } else if (a === 3) {
            cardDir = 'a_w';
        }
        return cardDir;
    }

    function drawBackgroundAndGrid(drawGrid) {
        p.background(255); // 209, 208, 166
        if (drawGrid) {
            p.push();
            p.fill(250);
            p.strokeWeight(1);
            p.rect(0, 0, d.sketchWidth, d.sketchHeight);
            p.line(d.sketchWidth / 2, 0, d.sketchWidth / 2, d.sketchHeight);
            p.line(0, d.sketchHeight / 2, d.sketchWidth, d.sketchHeight / 2);
            p.pop();
        }
    }
}

// function newOptimalPolicy() {
//     const policy = policyGridworld.getOptimalPolicy();
//     gw.updatePolicy(policy);
//     policyGridworld.updatePolicy(policy);
// }

// function getRandomDeterministicPolicy() {
//     const policy = policyGridworld.getRandomDeterministicPolicy();
//     gw.updatePolicy(policy);
//     policyGridworld.updatePolicy(policy);
// }
