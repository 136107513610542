import React, { Component } from 'react';

const nth = function(d) {
    if (d > 3 && d < 21) return 'th';
    switch (d % 10) {
        case 1:  return "st";
        case 2:  return "nd";
        case 3:  return "rd";
        default: return "th";
    }
}

const DateConverter = (props) => {
    const d = new Date(props.frontmatter.date);
    const day = d.getDate();
    const sfx = nth(day);
    return (
        <p className="dateauthor">
            {props.frontmatter.author} &nbsp; · &nbsp; {d.toLocaleDateString('default', {month: "long"}).toUpperCase()} {day.toString()},  {d.getFullYear()}
        </p>
    );
    // return (
    //     <p className="dateauthor">
    //         {d} · {props.frontmatter.author}· {props.frontmatter.bla}
    //     </p>
    // );
};

export default DateConverter;
