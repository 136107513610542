/* eslint-disable one-var */
/* eslint-disable prettier/prettier */
/* eslint-disable import/no-named-as-default */
/* eslint no-param-reassign: "off", no-use-before-define: "off" */
import Gridworld from './gridworldExtended';
import LearningCurve from './learningCurve';
import CurrentValues from './currentValues';
import OptVal from './algoOptVal';

import vars from '../../global-styles/_myVariables.scss';
// import "katex/dist/katex.min.css"

export default function sketch(p) {
    const kat = require('katex');

    const parentDiv = p.createDiv().parent();
    const sWidth = parentDiv.offsetWidth;

    // DESIGN DICT d
    const d = {
        // Basic dimensions
        sketchWidth: 400,
        sketchHeight: 450,
        kat: kat
    };

    // Base lengths, layout
    d.margin = d.sketchWidth / 30;
    d.hMargin = d.margin / 2;
    d.hSketchWidthMinusMargin = d.hSketchWidth - d.margin;
    d.hSketchWidth = d.sketchWidth / 2;
    d.hSketchHeight = d.sketchHeight / 2;
    d.tSketchWidth = d.sketchWidth / 3;
    d.tSketchHeight = d.sketchHeight / 3;

    // Dimensions of main components
    d.algoWidth = d.tSketchWidth; //  - 1 * d.margin
    // d.algoHeight = d.tSketchWidth - 1 * d.margin;
    d.gwWidth = d.sketchWidth * 0.95;
    d.gwHeight = d.gwWidth;

    d.lcWidth = d.tSketchWidth - 2 * d.margin;
    d.lcHeight = d.hSketchHeight;
    // d.lcHeightMinusMargin =  d.lcHeight - d.margin;
    d.cvWidth = d.algoWidth;
    d.algoParamWidth = d.lcWidth;

    // Gridworld
    d.numActions = 4;
    d.numColumns = 5;
    d.numRows = 5;
    d.numStates = d.numColumns * d.numRows;
    d.pcPositions = [23];
    d.mrPositions = [8, 10];
    d.cellSize = d.gwWidth / d.numColumns;
    d.innerCellMargin = d.cellSize / 10;
    d.emojiOffSetY = d.cellSize / 22;
    d.lcMaxReturn = 10;
    d.lcMinReturn = -10;

    // Positions of main elements
    d.xAgent = 0;
    d.yAgent = 0;
    d.xAgentAlgo = (d.sketchWidth * 2) / 3 + 2 * d.margin;
    d.yAgentAlgo = d.lcHeight + 2 * d.margin;
    d.xGw = 1;
    d.yGw = 1;
    d.xGwSet = d.xGw + d.hMargin; //  + d.hMargin
    d.yGwSet = d.yGw + d.gwHeight + d.margin / 2;
    d.yLc = 0;
    d.xLc = (d.sketchWidth * 2) / 3 + 2 * d.margin;
    // d.xCv = d.sketchWidth * 2 / 3 + 2 * d.margin;
    // d.yCv = d.lcHeight + 2 * d.margin;
    d.xCv = 0;
    d.yCv = d.gwHeight - (3 / 4) * d.margin;

    // Colors
    // d.bg = p.color(248);
    d.bg = p.color(vars.cBackgroundGrey);
    d.minColor = p.color(vars.cMinVal); // TODO: import from variables.scss
    d.maxColor = p.color(vars.cMaxVal);

    // Text sizes & related  TODO: check with scss
    d.emojiSize = 40;
    d.valueSize = 11;
    d.rewardSize = 13;
    d.rewardOffSetX = (1 / 3) * d.cellSize;
    d.rewardOffSetY = (3 / 8) * d.cellSize;
    d.policyArrowSize = (d.cellSize * 4) / 10;
    d.arrowHeadSize = 4;
    d.axisTickSize = d.margin / 7;
    d.lcLeftPadding = d.axisTickSize * 8; // Of the axis lines... this is the space for axis labels.
    // d.lcTopPadding = 1.5 * d.margin;
    d.lcTopPadding = 52; // 1.5 * d.margin;
    d.lcBottomPadding = 36; // 1.5 * d.margin;
    d.lcHeightMinusPadding = d.lcHeight - d.lcTopPadding - d.lcBottomPadding;
    d.axisLabelSize = 11;
    d.axisTickLabelSize = -1;
    d.lcCircleWidth = 5;

    // Time
    d.maxFramesTillStep = 50;
    d.fadeFrames = 40;
    d.framesPlay = 5; // Frames of agent movement when playing continuously (has to be less than the number of lines in pseudo code until next arrives at that point)
    d.framesStill = 20; // Frames of agent movement when playing line by line.
    d.framesLearn = 28; // Frames of arrow/reward movement when playing continuously.
    d.framesLearnStill = 28; // Frames of arrow/reward movement when playing playing line by line.
    d.afterLandingFrames = 7; // How long should reward and arrow stay on their target after arriving?

    // Titles
    d.lcTitle = 'LEARNING PERFORMANCE'; //  "LEARNING CURVE";
    d.algoTitle = 'Q-LEARNING'; // PSEUDO CODE of
    d.algoParamTitle = 'ALGORITHM PARAMETERS';
    d.currentVariablesTitle = ' CURRENT VARIABLE VALUES';

    // Init main components
    let agent;
    let gw;
    let lc;
    let cv;

    let framesTillStep;
    let frameSinceLast = 0;

    // Profiling
    let currentFr = 0;
    // let currentDLUL = 0;

    // These game-flow parameters are shared across many components.
    const sharedParams = {
        play: false,
        playOnce: false
    };

    // // Place the sketch into the left margin if window is wide enough
    let cnv = p.createCanvas(0, 0);
    const sketchContainer = cnv.parent();
    sketchContainer.id = 'sketchContainer';
    const p5parent = p.select('#sketchContainer');
    p5parent.style('position', 'relative');
    p5parent.style('width', `${d.sketchWidth}px`);
    // const windowMinusPadding = window.innerWidth - 36;
    // if (windowMinusPadding >= d.sketchWidth) {
    //     const articlePadding = 0.125 * windowMinusPadding;
    //     const articleWidth = Math.min(1400, 0.875 * windowMinusPadding);
    //     const articleLeftMargin = (windowMinusPadding - articleWidth) / 2;
    //     const parentLeft = articlePadding + articleLeftMargin + 18;
    //     const lM = `${Math.max(window.innerWidth - d.sketchWidth, 0) / 2 - parentLeft}px`;
    //     p5parent.style('margin-left', lM);
    // }

    p.setup = () => {
        cnv = p.createCanvas(d.sketchWidth, d.sketchHeight).id('algocanvas'); // .parent(cnvContainer)
        p.clear();
        p.smooth();
        p.textFont('Lato');
        gw = new Gridworld(p, d, sharedParams, undefined, 'q');
        // lc = new LearningCurve(p, d, sharedParams);
        agent = new OptVal(p, d, sharedParams, gw, lc);
        // cv = new CurrentValues(p, d, sharedParams, gw, lc);
        // connectAgent();
        // fillInCurrentValues();
        agent.policyEvaluation();
        agent.fromStateToActionValueFunction();
    };

    p.draw = () => {
        drawBackgroundAndGrid(false); // false
        // framesTillStep = d.maxFramesTillStep / agent.speedSlider.value();
        // d.framesPlay = Math.min(Math.floor(5 * framesTillStep), 30);
        frameSinceLast += 1;
        // if ((sharedParams.play && frameSinceLast >= framesTillStep) || sharedParams.playOnce) {
        //     frameSinceLast = 0;
        //     sharedParams.playOnce = false;
        //     agent.step();
        //     fillInCurrentValues();
        // }
        // lc.drawReturnPerEpisode();
        // agent.processDelayedQUpdates();
        gw.drawQagent(agent.qTable, true);
        // performanceProfiling();
    };

    function performanceProfiling() {
        if (p.frameCount % 30 === 0) {
            currentFr = p.nfc(p.frameRate(), 0);
            // currentDLUL = agent.qDelayedVizUpdateList.length;
        }
        // p.text(`len of list = ${currentDLUL}`, 1100, 420);
        p.text(`Frame rate = ${currentFr}`, 1100, 450);
    }

    function connectAgent() {
        for (let paramIx = 0; paramIx < agent.numParams; paramIx += 1) {
            agent.paramSliderList[paramIx]
                // eslint-disable-next-line no-loop-func
                .input(function() {
                    agent[agent.paramDict[paramIx].id] = this.value();
                    kat.render(
                        agent.paramDict[paramIx].lab + this.value(),
                        agent.paramLabList[paramIx].elt
                    );
                });
        }
        agent.buttonNext.mousePressed(() => {
            sharedParams.playOnce = true;
        });
        agent.buttonPlay.mousePressed(() => {
            sharedParams.play = !sharedParams.play;
            agent.buttonPlay.html(sharedParams.play ? 'pause' : 'play_arrow');
        });
        agent.buttonRestart.mousePressed(() => {
            agent.restart();
            lc.reset();
            gw.reset(undefined);
            sharedParams.play = false;
            agent.buttonPlay.html(sharedParams.play ? 'pause' : 'play_arrow');
            agent.isPlaying = false;
            fillInCurrentValues();
        });
    }

    p.keyPressed = () => {
        // Space bar --> play / pause
        if (p.keyCode === 32) {
            sharedParams.play = !sharedParams.play;
            agent.buttonPlay.html(sharedParams.play ? 'pause' : 'play_arrow');
        }
        // RIGHT / DOWN arrows for play once.
        if (p.keyCode === 39 || p.keyCode === 40) {
            sharedParams.playOnce = true;
        }
        return false;
    };

    function fillInCurrentValues() {
        kat.render(
            typeof agent.currentR === 'undefined' ? 'R = ' : `R = ${agent.currentR}`,
            cv.currentRLabel.elt
        );
        kat.render(
            typeof agent.currentS === 'undefined' ? 'S = ' : `S = s_{${agent.currentS}}`,
            cv.currentSLabel.elt
        ); //\\text{current state }
        kat.render(
            typeof agent.nextS === 'undefined' ? "S' = " : `S' = s_{${agent.nextS}}`,
            cv.nextSLabel.elt
        );
        kat.render(
            typeof agent.currentA === 'undefined'
                ? 'A = '
                : `A = ${actionToCardinalDir(agent.currentA)}`,
            cv.currentALabel.elt
        );
        kat.render(
            typeof agent.currentA === 'undefined' || typeof agent.currentS === 'undefined'
                ? 'Q(S, A) = '
                : 'Q(S, A) = ' +
                      Math.round(agent.qTable[agent.currentS][agent.currentA] * 100) / 100,
            cv.currentQLabel.elt
        );
        kat.render(
            typeof agent.nextS === 'undefined'
                ? "max_{a}Q(S', a) = "
                : "max_{a}Q(S', a) = " +
                      Math.round(Math.max(...agent.qTable[agent.nextS]) * 100) / 100,
            cv.nextQLabel.elt
        );
    }

    function actionToCardinalDir(a) {
        let cardDir;
        if (a === 0) {
            cardDir = 'north';
        } else if (a === 1) {
            cardDir = 'east';
        } else if (a === 2) {
            cardDir = 'south';
        } else if (a === 3) {
            cardDir = 'west';
        }
        return `\\text{${cardDir}}`;
    }

    function drawBackgroundAndGrid(drawGrid) {
        p.background(255); // 209, 208, 166
        if (drawGrid) {
            p.push();
            p.fill(250);
            p.strokeWeight(1);
            p.rect(0, 0, d.sketchWidth, d.sketchHeight);
            p.line(d.sketchWidth / 2, 0, d.sketchWidth / 2, d.sketchHeight);
            p.line(0, d.sketchHeight / 2, d.sketchWidth, d.sketchHeight / 2);
            p.pop();
        }
    }
}

// function newOptimalPolicy() {
//     const policy = policyGridworld.getOptimalPolicy();
//     gw.updatePolicy(policy);
//     policyGridworld.updatePolicy(policy);
// }

// function getRandomDeterministicPolicy() {
//     const policy = policyGridworld.getRandomDeterministicPolicy();
//     gw.updatePolicy(policy);
//     policyGridworld.updatePolicy(policy);
// }
