import vars from '../../global-styles/_myVariables.scss';

export default class Display {
    constructor(p, d, sharedParams, gw) {
        this.p = p;
        this.d = d;
        this.sharedParams = sharedParams;
        this.gw = gw;
        this.frames = 60;

        this.displayList = [];
    }

    pushWin() {
        this.displayList.push({
            text: 'You won!',
            framesLeft: this.frames,
            framesTotal: this.frames
        });
    }

    pushLoss() {
        this.displayList.push({
            text: 'You lost!',
            framesLeft: this.frames,
            framesTotal: this.frames
        });
    }

    draw() {
        let shiftUntil = 0;
        for (let dx = 0; dx < this.displayList.length; dx += 1) {
            const displayX = this.displayList[dx];
            displayX.framesLeft -= 1;
            if (displayX.framesLeft <= 0) {
                shiftUntil += 1;
            } else {
                this.p.push();
                this.p.textSize(30);
                this.p.textAlign(this.p.CENTER, this.p.CENTER);
                this.p.fill(
                    this.p.lerpColor(
                        this.p.color(vars.cAction),
                        this.p.color(255),
                        1 - displayX.framesLeft / displayX.framesTotal
                    )
                );
                // this.p.fill(0);
                // this.p.stroke(0);
                // this.p.translate(this.d.xDisplay, this.d.yDisplay);
                // console.log('displayX.text', displayX.text);
                // console.log('this.d.xDisplay, this.d.yDisplay', this.d.xDisplay);
                // console.log('this.d.xDisplay, this.d.yDisplay', this.d.yDisplay);
                this.p.text(displayX.text, this.d.xDisplay, this.d.yDisplay);
                this.p.pop();
            }
        }
        for (let jx = 0; jx < shiftUntil; jx += 1) {
            this.displayList.shift();
        }
    }
}
