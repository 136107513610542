/* eslint-disable no-nested-ternary */

export default class Gridworld {
    constructor(p, d, sharedParams, policy, gwType) {
        this.kat = require('katex');
        this.p = p;
        this.d = d;
        this.sharedParams = sharedParams;

        // Helper variables with often occuring sizes & lengths.
        this.xPos = d.xGw;
        this.yPos = d.yGw;
        this.hCellSize = this.d.cellSize / 2;
        this.boardHeight = this.d.numRows * this.d.cellSize;
        this.boardWidth = this.d.numColumns * this.d.cellSize;

        // Init Gridworld variables (states, actions, rewards)
        this.terminalStates = this.d.pcPositions.concat(this.d.mrPositions);
        this.states = [...Array(this.d.numStates).keys()];
        this.nonterminalStates = this.states.filter(value => {
            return !this.terminalStates.includes(value);
        }, this);
        this.numNonterminalStates = this.nonterminalStates.length;
        this.rewards = new Array(this.d.numStates).fill(-1);
        this.numRestarts = 0;
        for (let pcIx = 0; pcIx < this.d.pcPositions.length; pcIx += 1) {
            this.rewards[this.d.pcPositions[pcIx]] = 10;
        }
        for (let mrIx = 0; mrIx < this.d.mrPositions.length; mrIx += 1) {
            this.rewards[this.d.mrPositions[mrIx]] = -10;
        }

        // Gameflow variables
        this.currentState = undefined;
        this.activeAction = undefined;
        this.isTerminal = false;

        // Init visualization, etc.
        this.showStateLabels = false;
        this.showLearningUpdates = true;
        this.showRewards = true;
        this.showPolicy = false;
        this.fadingList = [];
        this.agentMovingList = [];
        this.learningUpdateList = [];
        this.stateLabelContainer = this.p.createDiv();
        this.stateLabels = new Array(this.d.numStates);
        this.setupStateLabels();
        if (['value', 'q'].includes(gwType)) {
            this.minValue = -5;
            this.maxValue = 10;
        }

        // Cache recurring values
        this.colDict = {};
        this.colDict[-10] = this.p.lerpColor(this.d.minColor, this.d.bg, 0);
        this.colDict[-1] = this.p.lerpColor(this.d.minColor, this.d.bg, 0.7);
        this.colDict[0] = this.d.bg;
        this.colDict[10] = this.d.maxColor;
        
        this.xs = [...Array(this.d.numColumns).keys()];
        this.ys = [...Array(this.d.numRows).keys()];
        this.xCenters = this.xs.map(x => (x + 0.5) * this.d.cellSize);
        this.yCenters = this.ys.map(y => (y + 0.5) * this.d.cellSize);
        this.yCentersRv = this.yCenters.slice().reverse();

        //
        // Create visibility buttons
        //
        this.numSettingsColumns = 2;
        this.settingsWidth = this.d.gwWidth - this.d.cellSize;

        this.settingsDiv = this.p
            .createDiv()
            .position(this.d.xGwSet, this.d.yGwSet)
            .style('width', `${this.settingsWidth}px`);

        this.settingsColWidth =
            (this.d.gwWidth -
                this.d.cellSize -
                d.hMargin -
                (this.numSettingsColumns - 1) * this.d.hMargin) /
            this.numSettingsColumns;
        this.settingsColWidthPx = `${this.settingsColWidth}px`;

        if (['value', 'q'].includes(gwType)) {
            // LearningUpdate
            this.buttonLearningUpdatesDiv = this.p
                .createDiv()
                .class('visButtonDiv')
                .style('width', this.settingsColWidthPx)
                .parent(this.settingsDiv);
            this.buttonLearningUpdates = this.p
                .createSpan(' Value updates')
                .class(this.showLearningUpdates ? 'visButtonActive' : 'visButton')
                .parent(this.buttonLearningUpdatesDiv)
                .mousePressed(() => {
                    this.showLearningUpdates = !this.showLearningUpdates;
                    this.buttonLearningUpdates.class(this.showLearningUpdates ? 'visButtonActive' : 'visButton');
                });
        }

        // VertSpaceDiv
        // this.p.createDiv().style("display", "inline-block").parent(this.settingsDiv).style("width", `${this.d.hMargin}px`);

        // Show rewards
        this.buttonShowRewardsDiv = this.p
            .createDiv()
            .class('visButtonDiv')
            .style("width", this.settingsColWidthPx)
            .parent(this.settingsDiv);
        this.buttonShowRewards = this.p
            .createSpan(' Rewards')
            .class(this.showRewards ? 'visButtonActive' : 'visButton')
            .parent(this.buttonShowRewardsDiv)
            .mousePressed(() => {
                this.showRewards = !this.showRewards;
                this.buttonShowRewards.class(this.showRewards ? 'visButtonActive' : 'visButton');
            });

        this.qLabel = this.p.createDiv().style('visibility', 'hidden').id('qLabel');
        this.kat.render("Q", this.qLabel.elt);

        if (['value', 'q'].includes(gwType)) {
            // Show policy
            this.buttonShowPolicyDiv = this.p
                .createDiv()
                .class('visButtonDiv')
                .style("width", this.settingsColWidthPx)
                .parent(this.settingsDiv);
            this.buttonShowPolicy = this.p
                .createSpan(' Greedy policy')
                .class(this.showPolicy ? 'visButtonActive' : 'visButton')
                .parent(this.buttonShowPolicyDiv)
                .mousePressed(() => {
                    this.showPolicy = !this.showPolicy;
                    this.buttonShowPolicy.class(this.showPolicy ? 'visButtonActive' : 'visButton');
                });
        }
        // VertSpaceDiv
        // this.p.createDiv().style("display", "inline-block").parent(this.settingsDiv).style("width", `${this.d.hMargin}px`);

        // State labels
        this.buttonStateLabelsDiv = this.p
            .createDiv()
            .class('visButtonDiv')
            .style("width", this.settingsColWidthPx)
            .parent(this.settingsDiv);
        this.buttonStateLabels = this.p
            .createSpan(' State labels')
            .class(this.showStateLabels ? 'visButtonActive' : 'visButton')
            .parent(this.buttonStateLabelsDiv)
            .mousePressed(() => {
                this.showStateLabels = !this.showStateLabels;
                const newProp = this.showStateLabels ? 'visible' : 'hidden';
                for (let ix = 1; ix < this.stateLabels.length; ix += 1) {
                    this.stateLabels[ix].style('visibility', newProp);
                }
                this.buttonStateLabels.class(
                    this.showStateLabels ? 'visButtonActive' : 'visButton'
                );
            });
    }

    reset(initialState) {
        // this.currentState = Math.floor(Math.random() * this.d.numColumns); // Random starting state s_0
        this.currentState = initialState;
        this.isTerminal = false;
        this.numRestarts += 1;
    }

    setupStateLabels() {
        for (let col = 0; col < this.d.numColumns; col += 1) {
            for (let row = 0; row < this.d.numRows; row += 1) {
                const ix = row * this.d.numColumns + col;
                const yMin = this.boardHeight - (row + 1) * this.d.cellSize;
                const xMin = col * this.d.cellSize;
                this.stateLabels[ix] = this.p
                    .createSpan()
                    .parent(this.stateLabelContainer)
                    .class('stateLabel')
                    .style('visibility', ix === 0 ? 'visible' : 'hidden')
                    .position(this.xPos + xMin + this.d.innerCellMargin, this.yPos + yMin);
                this.kat.render(`s_{${ix}}`, this.stateLabels[ix].elt);
            }
        }
    }

    drawQagent(qTable, showValues = true) {
        this.p.push();
        this.p.textAlign(this.p.CENTER, this.p.CENTER);
        this.p.strokeWeight(0.2);
        this.p.textSize(this.d.valueSize);
        this.p.translate(this.xPos, this.yPos);
        
        // Loop through every cell
        for (let col = 0; col < this.d.numColumns; col += 1) {
            for (let row = 0; row < this.d.numRows; row += 1) {
                const ix = row * this.d.numColumns + col;
                const yC = this.yCentersRv[row];
                const xC = this.xCenters[col];
                this.p.push();
                this.p.translate(xC, yC);
                this.p.fill(this.d.bg);
                this.p.rect(-this.hCellSize, -this.hCellSize, this.d.cellSize, this.d.cellSize);
                if (this.d.pcPositions.includes(ix)) {
                    this.p.textSize(this.d.emojiSize);
                    this.p.text("🥞", 0, this.d.emojiOffSetY); // + this.d.cellSize * 2.7 / 5
                    this.p.textSize(this.d.valueSize);
                } else if (this.d.mrPositions.includes(ix)) {
                    this.p.textSize(this.d.emojiSize);
                    this.p.text("🍄", 0, this.d.emojiOffSetY); // + this.d.cellSize * 2.7 / 5
                    this.p.textSize(this.d.valueSize);
                } else if (this.showPolicy || showValues) {
                    this.p.noStroke();
                    let maxDirs = [];
                    let maxActionValue = -Infinity;
                    // Loop through every direction.
                    for (let dir = 0; dir < 4; dir += 1) {
                        const valIx = qTable[ix][dir];
                        // Store which direction(s) is(are) argmax to draw policy later on.
                        if (valIx > maxActionValue) {
                            maxDirs = [dir];
                            maxActionValue = valIx;
                        } else if (valIx === maxActionValue) {
                            maxDirs.push(dir);
                        }
                        // Determine color of value arrow for this direction.
                        const colDir = this.getValueColor(valIx);
                        this.p.fill(colDir);
                        this.drawValueArrow();
                        if (!this.sharedParams.play && (ix === this.currentState) && (this.activeAction === dir)) {
                            this.drawPolicyArrow(this.p.createVector(0, -1), this.d.arrowHeadSize);
                        }
                        this.p.rotate(this.p.PI / 2);
                    }
                    if (this.showPolicy) {
                        for (let dirIx = 0; dirIx < maxDirs.length; dirIx += 1) {
                            const dir = maxDirs[dirIx];
                            const dirVec = this.polToDir(dir);
                            this.drawPolicyArrow(dirVec, this.d.arrowHeadSize);
                        }
                    }
                }
                if (this.showRewards) {
                    this.p.push();
                    const rewIx = this.rewards[ix];
                    const rewCol = this.colDict[rewIx];
                    this.p.stroke(rewCol);
                    this.p.fill(rewCol);
                    this.p.text(
                        (rewIx > 0 ? '+' : '') + this.p.nfc(rewIx, 0),
                        this.d.rewardOffSetX,
                        this.d.rewardOffSetY
                    );
                    this.p.pop();
                }
                this.p.pop();
            }
        }

        // console.log('showValues', showValues);
        let highlightIx = undefined;
        if (showValues) {
            const xBin = Math.floor((this.p.mouseX - this.xPos) / this.d.cellSize);
            const yBin = Math.floor((this.p.mouseY - this.yPos) / this.d.cellSize);
            if (xBin >= 0 && xBin < this.d.numColumns && yBin >= 0 && yBin < this.d.numRows) {
                highlightIx = (this.d.numRows - (yBin + 1)) * this.d.numColumns + xBin;
                if (
                    !(
                        this.d.pcPositions.includes(highlightIx) ||
                        this.d.mrPositions.includes(highlightIx)
                    )
                ) {
                    const yC = this.yCenters[yBin];
                    const xC = this.xCenters[xBin];
                    this.qLabel
                        .position(this.d.xGw + xC, this.d.yGw + yC)
                        .style('visibility', 'visible');
                    this.p.push();
                    this.p.translate(xC, yC);
                    this.p.textSize(10);
                    this.p.fill(20);
                    for (let dir = 0; dir < 4; dir += 1) {
                        const valIx = qTable[highlightIx][dir];
                        const dirVec = this.polToDir(dir);
                        this.p.text(this.p.nfc(valIx, 2), dirVec.x * this.hCellSize / 1.8, dirVec.y * this.hCellSize / 1.8);
                    }
                    this.p.pop();
                } else {
                    this.qLabel.style('visibility', 'hidden');
                }
            } else {
                this.qLabel.style('visibility', 'hidden');
            }
        }

        // Draw Agent (transparent if field is currently highlighted)
        let xAgent;
        let yAgent;
        if (this.agentMovingList.length > 0) {
            const agentPos = this.agentMovingList.shift();
            xAgent = agentPos.xPos;
            yAgent = agentPos.yPos;
        } else if (typeof this.currentState === 'undefined') {
            xAgent = this.hCellSize;
            yAgent = this.boardHeight + this.hCellSize;
        } else {
            const row = this.d.numRows - (Math.floor(this.currentState / this.d.numRows) + 1);
            const col = this.currentState % this.d.numRows;
            xAgent = this.xCenters[col];
            yAgent = this.yCenters[row];
        }
        this.p.push();
        if ((this.currentState !== undefined) && (this.currentState === highlightIx)) {
            this.p.fill(0, 0, 0, 40);
        }
        this.p.textSize(this.d.emojiSize - 2);
        this.p.text("🤖", xAgent, yAgent + this.d.emojiOffSetY);
        this.p.pop();

        // Show learning updates.
        if (this.showLearningUpdates) {
            this.p.push();
            this.p.textSize(this.d.rewardSize + 1);
            let shiftUntil = 0;
            for (let lux = 0; lux < this.learningUpdateList.length; lux += 1) {
                const lu = this.learningUpdateList[lux];
                if (lu.sleepFrames > 0) {
                    lu.sleepFrames -= 1;
                } else {
                    lu.frames -= 1;
                    if (lu.frames <= 0) {
                        shiftUntil += 1;
                    } else {
                        // Draw value arrow
                        if (!lu.isTerminal) {
                            this.p.push();
                            this.p.fill(lu.valCol);
                            this.p.translate(lu.xPosArrow[lu.ix], lu.yPosArrow[lu.ix]);
                            this.p.rotate(lu.arrowAngle[lu.ix]);
                            this.drawValueArrowCentered();
                            this.p.pop();
                        }
                        // Draw rewards
                        this.p.fill(lu.rewCol);
                        this.p.text(lu.reward, lu.xPosRew[lu.ix], lu.yPosRew[lu.ix]);
                    }
                    lu.ix += 1;
                }
            }
            for (let jx = 0; jx < shiftUntil; jx += 1) {
                this.learningUpdateList.shift();
            }
            this.p.pop();
            this.p.pop();
        }
    }

    addLearningUpdate(learnUpdateDict) {
        const targetDirVec = this.polToDir(learnUpdateDict.action);

        const targetRow = this.d.numRows - (Math.floor(learnUpdateDict.prevS / this.d.numRows) + 1);
        const targetCol = learnUpdateDict.prevS % this.d.numRows;
        const targetX = this.xCenters[targetCol] + (targetDirVec.x / 4) * this.d.cellSize;
        const targetY = this.yCenters[targetRow] + (targetDirVec.y / 4) * this.d.cellSize;

        const sourceRow = this.d.numRows - (Math.floor(learnUpdateDict.nextS / this.d.numRows) + 1);
        const sourceCol = learnUpdateDict.nextS % this.d.numRows;
        const sourceX = this.xCenters[sourceCol];
        const sourceY = this.yCenters[sourceRow];
        let sourceDirVec, sourceArrowX, sourceArrowY, sourceAngle, targetAngle;
        if (!learnUpdateDict.isTerminal) {
            sourceDirVec = this.polToDir(learnUpdateDict.maxQaction);
            sourceArrowX = sourceX + (sourceDirVec.x * this.hCellSize) / 2;
            sourceArrowY = sourceY + (sourceDirVec.y * this.hCellSize) / 2;
            targetAngle = this.polToRotAngle(learnUpdateDict.action);
            sourceAngle = this.polToRotAngle(learnUpdateDict.maxQaction);
            // Make sure that arrow turns in direction of shortest path
            if (Math.abs(targetAngle - sourceAngle) > this.p.PI + 0.00001) {
                if (targetAngle > sourceAngle) {
                    sourceAngle += 2 * this.p.PI;
                } else {
                    targetAngle += 2 * this.p.PI;
                }
            }
        }
        const sourceRewX = sourceX + (1 / 3) * this.d.cellSize;
        const sourceRewY = sourceY + (3 / 8) * this.d.cellSize;

        const numFrames = learnUpdateDict.frames;
        for (let ix = 0; ix < numFrames; ix += 1) {
            let inter = Math.min(ix / (numFrames - this.d.afterLandingFrames), 1.0);
            inter = this.easeInOutCubic(inter);
            if (!learnUpdateDict.isTerminal) {
                learnUpdateDict.xPosArrow.push(this.p.lerp(sourceArrowX, targetX, inter));
                learnUpdateDict.yPosArrow.push(this.p.lerp(sourceArrowY, targetY, inter));
                learnUpdateDict.arrowAngle.push(this.p.lerp(sourceAngle, targetAngle, inter));
            }
            learnUpdateDict.xPosRew.push(this.p.lerp(sourceRewX, targetX, inter));
            learnUpdateDict.yPosRew.push(this.p.lerp(sourceRewY, targetY, inter));
        }

        // Reward color
        const rewIx = this.rewards[learnUpdateDict.nextS];
        const rewCol = this.colDict[rewIx];

        // Value arrow color
        const valIx = learnUpdateDict.maxQ;
        learnUpdateDict.valCol = this.getValueColor(valIx);
        learnUpdateDict.rewCol = rewCol;
        learnUpdateDict.ix = 0;

        this.learningUpdateList.push(learnUpdateDict);
    }

    step(action) {
        const previousState = this.currentState;
        if (action === 0) {
            // north
            if (this.currentState < this.d.numStates - this.d.numColumns) {
                this.currentState += this.d.numColumns;
            }
        } else if (action === 1) {
            // east
            if (this.currentState % this.d.numColumns < this.d.numColumns - 1) {
                this.currentState += 1;
            }
        } else if (action === 2) {
            // south
            if (this.currentState >= this.d.numColumns) {
                this.currentState -= this.d.numColumns;
            }
        } else if (action === 3) {
            // west
            if (this.currentState % this.d.numColumns > 0) {
                this.currentState -= 1;
            }
        }
        const nextState = this.currentState;
        this.isTerminal = this.terminalStates.includes(nextState);

        if (previousState !== undefined) {
            const previousRow = this.d.numRows - (Math.floor(previousState / this.d.numRows) + 1);
            const previousCol = previousState % this.d.numRows;
            const previousX = (previousCol + 0.5) * this.d.cellSize;
            const previousY = (previousRow + 0.5) * this.d.cellSize;
            if (nextState !== previousState) {
                const nextRow = this.d.numRows - (Math.floor(nextState / this.d.numRows) + 1);
                const nextCol = nextState % this.d.numRows;
                const nextX = (nextCol + 0.5) * this.d.cellSize;
                const nextY = (nextRow + 0.5) * this.d.cellSize;
                const numFrames = this.sharedParams.play
                    ? this.isTerminal
                        ? this.d.framesPlay - 2
                        : this.d.framesPlay
                    : this.d.framesStill;
                for (let ix = 0; ix < numFrames; ix += 1) {
                    let inter = ix / numFrames;
                    inter = this.easeInOutCubic(inter);
                    this.agentMovingList.push({
                        xPos: this.p.lerp(previousX, nextX, inter),
                        yPos: this.p.lerp(previousY, nextY, inter)
                    });
                }
            } else {
                // Walked into wall
                const dirVec = this.polToDir(action);
                const nextX = previousX + dirVec.x * 0.8 * this.d.cellSize;
                const nextY = previousY + dirVec.y * 0.8 * this.d.cellSize;
                const numFrames = this.sharedParams.play ? this.d.framesPlay : this.d.framesStill;
                for (let ix = 1; ix < Math.ceil(numFrames / 2) + 1; ix += 1) {
                    let inter = ix / numFrames;
                    inter = this.easeInOutCubic(inter);
                    this.agentMovingList.push({
                        xPos: this.p.lerp(previousX, nextX, inter),
                        yPos: this.p.lerp(previousY, nextY, inter)
                    });
                }
                for (let ix = Math.ceil(numFrames / 2); ix > 0; ix -= 1) {
                    let inter = ix / numFrames;
                    inter = this.easeInOutCubic(inter);
                    this.agentMovingList.push({
                        xPos: this.p.lerp(previousX, nextX, inter),
                        yPos: this.p.lerp(previousY, nextY, inter)
                    });
                }
            }
        }
        const rew = this.rewards[this.currentState];
        return rew;
    }

    getValueColor(val) {
        let valCol;
        if (val === 0) {
            valCol = this.d.bg;
        } else if (val > 0) {
            const inter = this.p.map(val, 0, this.maxValue, 0, 1);
            valCol = this.p.lerpColor(this.d.bg, this.d.maxColor, inter);
        } else {
            const inter = this.p.map(val, this.minValue, 0, 0, 1);
            valCol = this.p.lerpColor(this.d.minColor, this.d.bg, inter);
        }
        return valCol;
    }

    drawPolicyArrow(vec) {
        this.p.push();
        this.p.stroke(20);
        this.p.fill(20);
        this.p.strokeWeight(1.5);
        this.p.line(0, 0, vec.x * this.d.policyArrowSize, vec.y * this.d.policyArrowSize);
        this.p.rotate(vec.heading());
        this.p.translate(vec.mag() * this.d.policyArrowSize - this.d.arrowHeadSize, 0);
        this.p.triangle(0, this.d.arrowHeadSize / 2, 0, -this.d.arrowHeadSize / 2, this.d.arrowHeadSize, 0);
        this.p.pop();
    }

    drawValueArrow() {
        this.p.beginShape();
        this.p.vertex(-0.15 * this.hCellSize, -0.25 * this.hCellSize);
        this.p.vertex(-0.15 * this.hCellSize, -0.5 * this.hCellSize);
        this.p.vertex(-0.4 * this.hCellSize, -0.5 * this.hCellSize);
        this.p.vertex(0, -0.9 * this.hCellSize);
        this.p.vertex(0.4 * this.hCellSize, -0.5 * this.hCellSize);
        this.p.vertex(0.15 * this.hCellSize, -0.5 * this.hCellSize);
        this.p.vertex(0.15 * this.hCellSize, -0.25 * this.hCellSize);
        this.p.endShape(this.p.CLOSE);
    }

    drawValueArrowCentered() {
        this.p.beginShape();
        this.p.vertex(-0.15 * this.hCellSize, 0.25 * this.hCellSize);
        this.p.vertex(-0.15 * this.hCellSize, 0);
        this.p.vertex(-0.4 * this.hCellSize, 0);
        this.p.vertex(0, -0.4 * this.hCellSize);
        this.p.vertex(0.4 * this.hCellSize, 0);
        this.p.vertex(0.15 * this.hCellSize, 0);
        this.p.vertex(0.15 * this.hCellSize, 0.25 * this.hCellSize);
        this.p.endShape(this.p.CLOSE);
    }

    // eslint-disable-next-line class-methods-use-this
    easeInOutCubic(x) {
        return x < 0.5 ? 4 * x * x * x : 1 - ((-2 * x + 2) ** 3) / 2;
    }

    polToDir(polInt) {
        let dirVec;
        if (polInt === 0) {
            dirVec = this.p.createVector(0, -1);
        } else if (polInt === 1) {
            dirVec = this.p.createVector(1, 0);
        } else if (polInt === 2) {
            dirVec = this.p.createVector(0, 1);
        } else if (polInt === 3) {
            dirVec = this.p.createVector(-1, 0);
        }
        return dirVec;
    }

    polToRotAngle(polInt) {
        // In radians
        const polToRotAngle = (polInt * this.p.PI) / 2;
        return polToRotAngle;
    }

    getNextState(state) {
        const action = this.policy[state];
        let nextState = state;
        if (action === 0) {
            // north
            if (state < this.d.numStates - this.d.numColumns) {
                nextState = state + this.d.numColumns;
            }
        } else if (action === 1) {
            // east
            if (state % this.d.numColumns < this.d.numColumns - 1) {
                nextState = state + 1;
            }
        } else if (action === 2) {
            // south
            if (state >= this.d.numColumns) {
                nextState = state - this.d.numColumns;
            }
        } else if (action === 3) {
            // west
            if (state % this.d.numColumns > 0) {
                nextState = state - 1;
            }
        }
        return nextState;
    }

    // eslint-disable-next-line class-methods-use-this
    getOptimalPolicy() {
        // eslint-disable-next-line prettier/prettier
        const policy = [0, 0, 0, 0, 0,
                       0, 0, 0, 0, 0,
                       0, 0, 0, 3, 0,
                       0, 0, 0, 0, 0,
                       1, 1, 1, 0, 3]
        return policy;
    }

    getRandomDeterministicPolicy() {
        const policy = Array.from({ length: this.d.numStates }, () => Math.floor(Math.random() * 4));
        return policy;
    }

    updatePolicy(policy) {
        this.policy = policy;
        this.policyDirs = this.policy.map(x => this.polToDir(x));
    }
    // if (typeof policy === 'undefined') {
    //     const pol = this.getOptimalPolicy();
    //     this.updatePolicy(pol);
    // } else {
    //     this.updatePolicy(policy);
    // }
}
