import Layout from "../../../../src/components/blogLayout";
import Sketch from "../../../../src/components/sketch";
import { KatexBlock, KatexInline } from "../../../../src/components/Katex";
import { Link } from 'gatsby';
import DateConverter from "../../../../src/components/dateConverter";
import { SideNote, MarginNote } from "../../../../src/components/sideNote";
import dSDM from "../../../../src/scripts/diagrams/dSDM";
import dMDPlight from "../../../../src/scripts/diagrams/dMDPlight";
import NewThought from "../../../../src/components/newThought";
import Commento from "../../../../src/components/commento";
import * as React from 'react';
export default {
  Layout,
  Sketch,
  KatexBlock,
  KatexInline,
  Link,
  DateConverter,
  SideNote,
  MarginNote,
  dSDM,
  dMDPlight,
  NewThought,
  Commento,
  React
};