/* eslint-disable no-nested-ternary */
import vars from '../../global-styles/_myVariables.scss';

export default function sketch(p) {
    const kat = require('katex');
    const parentDiv = p.createDiv().parent();
    const sWidth = parentDiv.offsetWidth;
    // console.log('sWidth', sWidth);
    // DESIGN DICT d
    const d = { sketchWidth: Math.max(sWidth, 550) };
    d.hSketchWidth = d.sketchWidth / 2;
    // d.vertAlign = false;
    d.numBoxes = 5;
    const ixes = [...Array(d.numBoxes).keys()];
    d.boxWidth = 125;
    d.boxHeight = 80;
    d.hBoxWidth = d.boxWidth / 2;
    d.hBoxHeight = d.boxHeight / 2;

    d.xDist = 150; // 2 * d.boxWidth;
    d.yDist = 1.5 * d.boxHeight;
    d.xStart = d.hSketchWidth + d.xDist / 2;
    d.yStart = d.hBoxHeight + 15;

    d.sketchHeight = d.yStart + 2 * d.yDist + d.boxHeight;
    // d.sketchHeight = 400;

    d.cornerRadius = 5;
    d.arrowHeadSize = 7;

    p.setup = () => {
        const cnv = p.createCanvas(d.sketchWidth, d.sketchHeight).id('algocanvas'); // // .parent(cnvContainer)
        const sketchContainer = cnv.parent();
        sketchContainer.id = 'sketchContainerdMDP';
        // sketchContainer.classList.add('sketchContainer');
        const p5parent = p.select('#sketchContainerdMDP');
        p5parent.style('position', 'relative');
        p.clear();
        p.smooth();
        p.textFont(vars.fontSansString);
        const boxLabels = [
            '\\text{🤖 Policy} \\\\ A_t = \\pi(S_t)',
            '\\text{Action} \\\\ A_t',
            "\\text{🌍} \\\\ R_{t+1} = r(S_t, A_t) \\\\ S_{t+1} = s'(S_t, A_t)",
            '\\text{State} \\\\ S_t',
            '\\text{Reward} \\\\ R_t'
        ];
        const isKatex = [true, true, true, true, true];
        const xes = [
            d.xStart,
            d.xStart + d.xDist,
            d.xStart,
            d.xStart - 2 * d.xDist,
            d.xStart - 1 * d.xDist
        ];
        const yes = [
            d.yStart,
            d.yStart + d.yDist,
            d.yStart + 2 * d.yDist,
            d.yStart + d.yDist,
            d.yStart + d.yDist
        ];
        const connects = [
            [0, 1],
            [1, 2],
            [2, 3],
            [2, 4],
            [3, 0],
            [4, 0]
        ];

        // const arrowLabels = ['observed by', 'selects'];
        p.fill(vars.cVLGrey);
        p.rectMode(p.CENTER);
        p.textAlign(p.CENTER, p.CENTER);

        // Boxes
        for (let ix = 0; ix < ixes.length; ix += 1) {
            p.fill(vars.cVVLGrey);
            p.noStroke();
            p.rect(xes[ix], yes[ix], d.boxWidth, d.boxHeight, d.cornerRadius);
            if (!isKatex[ix]) {
                const isEmoji = /\p{Extended_Pictographic}/u.test(boxLabels[ix]);
                p.fill(vars.cAlmostBlack);
                // p.stroke(vars.cAlmostBlack);
                p.textSize(isEmoji ? 35 : 14);
                p.text(boxLabels[ix], xes[ix], yes[ix] + (isEmoji ? 4 : 0));
            }
        }

        // Arrows
        p.fill(vars.cLGrey);
        p.stroke(vars.cLGrey);
        for (let ix = 0; ix < connects.length; ix += 1) {
            const startNode = connects[ix][0];
            const endNode = connects[ix][1];
            const x1 = xes[startNode];
            const y1 = yes[startNode];
            const x2 = xes[endNode];
            const y2 = yes[endNode];
            const arrowType = x1 > x2 ? (y1 > y2 ? 'brtl' : 'trbl') : y1 > y2 ? 'bltr' : 'tlbr';
            // console.log('arrowType', arrowType);
            let xOffSetStart;
            let yOffSetStart;
            let xOffSetEnd;
            let yOffSetEnd;
            if (arrowType === 'bltr') {
                xOffSetStart = 0;
                yOffSetStart = -d.hBoxHeight;
                xOffSetEnd = -d.hBoxWidth - d.arrowHeadSize;
                yOffSetEnd = 0;
            } else if (arrowType === 'tlbr') {
                xOffSetStart = d.hBoxWidth;
                yOffSetStart = 0;
                xOffSetEnd = 0;
                yOffSetEnd = -d.hBoxHeight - d.arrowHeadSize;
            } else if (arrowType === 'trbl') {
                xOffSetStart = 0;
                yOffSetStart = d.hBoxHeight;
                xOffSetEnd = d.hBoxWidth + d.arrowHeadSize;
                yOffSetEnd = 0;
            } else if (arrowType === 'brtl') {
                xOffSetStart = -d.hBoxWidth;
                yOffSetStart = 0;
                xOffSetEnd = 0;
                yOffSetEnd = d.hBoxHeight + d.arrowHeadSize;
            }

            drawCornerArrow(
                arrowType,
                p.createVector(x1 + xOffSetStart, y1 + yOffSetStart),
                p.createVector(x2 + xOffSetEnd, y2 + yOffSetEnd)
            );
        }

        // // Arrow labels
        // p.fill(vars.cLGrey);
        // p.noStroke();
        // p.textSize(13);
        // for (let ix = 0; ix < ixes.length - 1; ix += 1) {
        //     const xOffSet = d.vertAlign ? 0 : d.xDist / 2;
        //     const yOffSet = d.vertAlign ? d.yDist / 2 : -10;
        //     p.text(arrowLabels[ix], xes[ix] + xOffSet, yes[ix] + yOffSet);
        // }

        // Draw Katex labels
        const katexPs = [
            p
                .createDiv()
                .parent(p5parent)
                .class('katexLabel')
                .style('z-index', 999),
            p
                .createDiv()
                .parent(p5parent)
                .class('katexLabel')
                .style('z-index', 999),
            p
                .createDiv()
                .parent(p5parent)
                .class('katexLabel')
                .style('z-index', 999),
            p
                .createDiv()
                .parent(p5parent)
                .class('katexLabel')
                .style('z-index', 999),
            p
                .createP()
                .parent(p5parent)
                .class('katexLabel')
                .style('z-index', 999)
        ];
        for (let ix = 0; ix < ixes.length; ix += 1) {
            if (isKatex[ix]) {
                katexPs[ix].position(xes[ix], yes[ix], 'absolute');
                kat.render(boxLabels[ix], katexPs[ix].elt);
            }
        }

        p.noLoop();

        // ctx.beginPath();
        // ctx.moveTo(x + tl, y);
        // ctx.arcTo(x + w, y, x + w, y + h, tr);
        // ctx.arcTo(x + w, y + h, x, y + h, br);
        // ctx.arcTo(x, y + h, x, y, bl);
        // ctx.arcTo(x, y, x + w, y, tl);
        // ctx.closePath();
    };

    function drawCornerArrow(arrowType, vecStartPos, vecEndPos) {
        let cornerPos;
        let cornerPosStart;
        let cornerPosEnd;
        let arcPos;
        let arcStart;
        let arcEnd;
        // const cornerPos = ['bltr', 'trbl'].includes(arrowType)
        // ? p.createVector(vecStartPos.x, vecEndPos.y)
        // : p.createVector(vecEndPos.x, vecStartPos.y);
        if (arrowType === 'bltr') {
            cornerPos = p.createVector(vecStartPos.x, vecEndPos.y);
            cornerPosStart = p.createVector(cornerPos.x, cornerPos.y + d.cornerRadius);
            cornerPosEnd = p.createVector(cornerPos.x + d.cornerRadius, cornerPos.y);
            arcPos = p.createVector(cornerPos.x + d.cornerRadius, cornerPos.y + d.cornerRadius);
            arcStart = p.PI;
            arcEnd = p.PI + p.HALF_PI;
        } else if (arrowType === 'tlbr') {
            cornerPos = p.createVector(vecEndPos.x, vecStartPos.y);
            cornerPosStart = p.createVector(cornerPos.x - d.cornerRadius, cornerPos.y);
            cornerPosEnd = p.createVector(cornerPos.x, cornerPos.y + d.cornerRadius);
            arcPos = p.createVector(cornerPos.x - d.cornerRadius, cornerPos.y + d.cornerRadius);
            arcStart = p.PI + p.HALF_PI;
            arcEnd = p.TWO_PI;
        } else if (arrowType === 'trbl') {
            cornerPos = p.createVector(vecStartPos.x, vecEndPos.y);
            cornerPosStart = p.createVector(cornerPos.x, cornerPos.y - d.cornerRadius);
            cornerPosEnd = p.createVector(cornerPos.x - d.cornerRadius, cornerPos.y);
            arcPos = p.createVector(cornerPos.x - d.cornerRadius, cornerPos.y - d.cornerRadius);
            arcStart = 0;
            arcEnd = p.HALF_PI;
        } else if (arrowType === 'brtl') {
            cornerPos = p.createVector(vecEndPos.x, vecStartPos.y);
            cornerPosStart = p.createVector(cornerPos.x + d.cornerRadius, cornerPos.y);
            cornerPosEnd = p.createVector(cornerPos.x, cornerPos.y - d.cornerRadius);
            arcPos = p.createVector(cornerPos.x + d.cornerRadius, cornerPos.y - d.cornerRadius);
            arcStart = p.HALF_PI;
            arcEnd = p.PI;
        }
        p.push();
        p.strokeWeight(1.5);
        p.line(vecStartPos.x, vecStartPos.y, cornerPosStart.x, cornerPosStart.y);
        p.noFill();
        p.arc(arcPos.x, arcPos.y, 2 * d.cornerRadius, 2 * d.cornerRadius, arcStart, arcEnd);
        p.fill(vars.cLGrey);
        p.line(cornerPosEnd.x, cornerPosEnd.y, vecEndPos.x, vecEndPos.y);
        p.translate(vecEndPos.x, vecEndPos.y);
        const vecArrow = vecEndPos.sub(cornerPosEnd);
        p.rotate(vecArrow.heading());

        p.triangle(0, d.arrowHeadSize / 2, 0, -d.arrowHeadSize / 2, d.arrowHeadSize, 0);
        p.pop();
    }

    function drawArrow(vecStartPos, vecEndPos) {
        const vecArrow = vecEndPos.sub(vecStartPos);
        p.push();
        p.translate(vecStartPos.x, vecStartPos.y);
        p.strokeWeight(1.5);
        p.line(0, 0, vecArrow.x * 0.96, vecArrow.y * 0.96);
        p.rotate(vecArrow.heading());
        p.translate(vecArrow.mag() * 0.97 - d.arrowHeadSize, 0);
        p.triangle(0, d.arrowHeadSize / 2, 0, -d.arrowHeadSize / 2, d.arrowHeadSize, 0);
        p.pop();
    }

    p.draw = () => {
        console.log('This should not be printed more than once because in noLoop() mode.');
    };

    function drawBackgroundAndGrid(drawGrid) {
        p.background(230); // 209, 208, 166
        if (drawGrid) {
            p.push();
            p.fill(250);
            p.strokeWeight(1);
            p.rect(0, 0, d.sketchWidth, d.sketchHeight);
            p.line(d.hSketchWidth, 0, d.hSketchWidth, d.sketchHeight);
            p.line(0, d.sketchHeight / 2, d.sketchWidth, d.sketchHeight / 2);
            p.pop();
        }
    }

    function performanceProfiling() {
        let currentFr;
        if (p.frameCount % 30 === 0) {
            currentFr = p.nfc(p.frameRate(), 0);
        }
        p.text(`Frame rate = ${currentFr}`, 0, 0);
    }
}
