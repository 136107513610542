/* eslint-disable one-var */
/* eslint-disable prettier/prettier */
/* eslint-disable import/no-named-as-default */
/* eslint no-param-reassign: "off", no-use-before-define: "off" */
import Gridworld from './gridworldExtended';
import QPolicy from './algoPolicy';
import vars from '../../global-styles/_myVariables.scss';
// import "katex/dist/katex.min.css"

export default function sketch(p) {
    const kat = require('katex');

    const parentDiv = p.createDiv().parent();
    const sWidth = parentDiv.offsetWidth;

    // DESIGN DICT d
    const d = {
        // Basic dimensions
        sketchWidth: 600,
        sketchHeight: 350,
        kat: kat
    };

    // Base lengths, layout
    d.margin = d.sketchWidth / 30;
    d.hMargin = d.margin / 2;
    d.hSketchWidthMinusMargin = d.hSketchWidth - d.margin;
    d.hSketchWidth = d.sketchWidth / 2;
    d.hSketchHeight = d.sketchHeight / 2;
    d.tSketchWidth = d.sketchWidth / 3;
    d.tSketchHeight = d.sketchHeight / 3;

    // Dimensions of main components
    d.algoWidth = d.tSketchWidth; //  - 1 * d.margin
    // d.algoHeight = d.tSketchWidth - 1 * d.margin;
    d.gwWidth = d.hSketchWidth - d.hMargin;
    d.gwHeight = d.gwWidth;

    d.lcWidth = d.tSketchWidth - 2 * d.margin;
    d.lcHeight = d.hSketchHeight;
    // d.lcHeightMinusMargin =  d.lcHeight - d.margin;
    d.cvWidth = d.algoWidth;
    d.algoParamWidth = d.lcWidth;

    // Gridworld
    d.numActions = 4;
    d.numColumns = 5;
    d.numRows = 5;
    d.numStates = d.numColumns * d.numRows;
    d.pcPositions = [9];
    d.mrPositions = [1, 2, 3, 4];
    d.cellSize = d.gwWidth / d.numColumns;
    d.innerCellMargin = d.cellSize / 10;
    d.emojiOffSetY = d.cellSize / 22;
    d.lcMaxReturn = 10;
    d.lcMinReturn = -10;

    // Positions of main elements
    d.xAgent = 0;
    d.yAgent = 0;
    d.xAgentAlgo = (d.sketchWidth * 2) / 3 + 2 * d.margin;
    d.yAgentAlgo = d.lcHeight + 2 * d.margin;
    d.xGw = 1;
    d.yGw = 2 * d.margin; // 1;
    d.xGwSet = d.xGw + d.hMargin; //  + d.hMargin
    d.yGwSet = d.yGw + d.gwHeight + d.margin / 2;
    d.yLc = 0;
    d.xLc = (d.sketchWidth * 2) / 3 + 2 * d.margin;
    // d.xCv = d.sketchWidth * 2 / 3 + 2 * d.margin;
    // d.yCv = d.lcHeight + 2 * d.margin;
    d.xCv = 0;
    d.yCv = d.gwHeight - (3 / 4) * d.margin;

    // Colors
    // d.bg = p.color(248);
    d.bg = p.color(vars.cBackgroundGrey);
    d.minColor = p.color(vars.cMinVal); // TODO: import from variables.scss
    d.maxColor = p.color(vars.cMaxVal);

    // Text sizes & related  TODO: check with scss
    d.emojiSize = 40;
    d.valueSize = 11;
    d.rewardSize = 13;
    d.rewardOffSetX = (3 / 7) * d.cellSize;
    d.rewardOffSetY = (3 / 8) * d.cellSize;
    d.policyArrowSize = (d.cellSize * 4) / 10;
    d.arrowHeadSize = 4;
    d.axisTickSize = d.margin / 7;
    d.lcLeftPadding = d.axisTickSize * 8; // Of the axis lines... this is the space for axis labels.
    // d.lcTopPadding = 1.5 * d.margin;
    d.lcTopPadding = 52; // 1.5 * d.margin;
    d.lcBottomPadding = 36; // 1.5 * d.margin;
    d.lcHeightMinusPadding = d.lcHeight - d.lcTopPadding - d.lcBottomPadding;
    d.axisLabelSize = 11;
    d.axisTickLabelSize = -1;
    d.lcCircleWidth = 5;

    // Time
    d.maxFramesTillStep = 50;
    d.fadeFrames = 40;
    d.framesPlay = 5; // Frames of agent movement when playing continuously (has to be less than the number of lines in pseudo code until next arrives at that point)
    d.framesStill = 20; // Frames of agent movement when playing line by line.
    d.framesLearn = 28; // Frames of arrow/reward movement when playing continuously.
    d.framesLearnStill = 28; // Frames of arrow/reward movement when playing playing line by line.
    d.afterLandingFrames = 7; // How long should reward and arrow stay on their target after arriving?

    // Titles
    d.lcTitle = 'LEARNING PERFORMANCE'; //  "LEARNING CURVE";
    d.algoTitle = 'Q-LEARNING'; // PSEUDO CODE of
    d.algoParamTitle = 'ALGORITHM PARAMETERS';
    d.currentVariablesTitle = ' CURRENT VARIABLE VALUES';

    // Init main components
    let agent;
    let gw;
    let lc;
    let cv;

    let framesTillStep;
    let frameSinceLast = 0;

    // Profiling
    let currentFr = 0;
    // let currentDLUL = 0;

    // These game-flow parameters are shared across many components.
    const sharedParams = {
        play: false,
        playOnce: false
    };

    // // Place the sketch into the left margin if window is wide enough
    let cnv = p.createCanvas(0, 0);
    const sketchContainer = cnv.parent();
    sketchContainer.id = 'sketchContainerPolicy';
    const p5parent = p.select('#sketchContainerPolicy');
    p5parent.addClass('inlineSketchContainer');
    p5parent.style('position', 'relative');
    p5parent.style('width', `${d.sketchWidth}px`);
    p.setup = () => {
        cnv = p.createCanvas(d.sketchWidth, d.sketchHeight).id('algocanvas'); // .parent(cnvContainer)
        p.clear();
        p.smooth();
        p.textFont('Lato');
        const titleDiv = p
            .createDiv()
            .style('width', `${d.sketchWidth}px`)
            .position(0, 0);
        p.createP('Q-learning trajectory')
            .parent(titleDiv)
            .position(0, 0)
            .style('width', `${d.sketchWidth / 2 - d.hMargin}px`)
            .class('sketchSubTitle');
        p.createP('Sarsa trajectory')
            .parent(titleDiv)
            .position(d.sketchWidth / 2 + d.hMargin, 0)
            .style('width', `${d.sketchWidth / 2 - d.hMargin}px`)
            .class('sketchSubTitle');
        const qPolicy = [
            0,
            -1,
            -1,
            -1,
            -1,
            1,
            1,
            1,
            1,
            -1,
            -1,
            -1,
            -1,
            -1,
            -1,
            -1,
            -1,
            -1,
            -1,
            -1,
            -1,
            -1,
            -1,
            -1,
            -1
        ];
        gw = new Gridworld(p, d, sharedParams, undefined, 'q', qPolicy);
        const e = d;
        e.xGw = d.hSketchWidth + d.hMargin - 1;
        const sarsaPolicy = [
            0,
            -1,
            -1,
            -1,
            -1,
            0,
            -1,
            -1,
            -1,
            -1,
            1,
            1,
            1,
            1,
            2,
            -1,
            -1,
            -1,
            -1,
            -1,
            -1,
            -1,
            -1,
            -1,
            -1
        ];
        const gwSarsa = new Gridworld(p, d, sharedParams, undefined, 'q', sarsaPolicy);
        agent = new QPolicy(p, d, sharedParams, gw, lc);
        drawBackgroundAndGrid(false);
        gw.drawQagent(agent.qTable, true);
        gwSarsa.drawQagent(agent.qTable, true);
        p.noLoop();
    };

    function drawBackgroundAndGrid(drawGrid) {
        p.background(255); // 209, 208, 166
        if (drawGrid) {
            p.push();
            p.fill(250);
            p.strokeWeight(1);
            p.rect(0, 0, d.sketchWidth, d.sketchHeight);
            p.line(d.sketchWidth / 2, 0, d.sketchWidth / 2, d.sketchHeight);
            p.line(0, d.sketchHeight / 2, d.sketchWidth, d.sketchHeight / 2);
            p.pop();
        }
    }
}
