import React from 'react';
import { Link } from 'gatsby';
import headerStyles from './header.module.scss';

const Header = () => {
    return (
        <header className={headerStyles.header}>
            {/* <h1>
                <Link className={headerStyles.title} to="/">
                Andrew Mead
                </Link>
            </h1> */}
            <nav>
                <ul className={headerStyles.navList}>
                    <li>
                        <Link
                            className={headerStyles.navItem}
                            activeClassName={headerStyles.activeNavItem}
                            to="/"
                        >
                            /
                        </Link>
                    </li>
                    <li>
                        <Link
                            className={headerStyles.navItem}
                            activeClassName={headerStyles.activeNavItem}
                            to="/about"
                        >
                            about
                        </Link>
                    </li>
                    <li>
                        <Link
                            className={headerStyles.navItem}
                            activeClassName={headerStyles.activeNavItem}
                            to="/blog"
                        >
                            blog
                        </Link>
                    </li>
                    {/* <li>
                        <Link
                            className={headerStyles.navItem}
                            activeClassName={headerStyles.activeNavItem}
                            to="/publications"
                        >
                            publications
                        </Link>
                    </li> */}
                </ul>
            </nav>
        </header>
    );
};

export default Header;
