export default class CurrentValues {
    constructor(p, d, sharedParams, gw, lc) {
        this.p = p;
        this.d = d;
        this.sharedParams = sharedParams;
        this.gw = gw;
        this.lc = lc;


        this.cvDiv = this.p
            .createDiv()
            // .parent(this.agent.algoDiv)
            // .style('margin-top', '1.2em')
            .position(this.d.xCv, this.d.yCv)
            .style('width', `${this.d.cvWidth}px`);

        this.cvTitle = this.p.createP(this.d.currentVariablesTitle) // "CURRENT VARIABLE VALUES"
            .parent(this.cvDiv)
            .class('sketchSubTitle')
            .style('width', `${this.d.cvWidth}px`)
            .addClass(this.showCurrentValues ? 'visButtonActive' : 'visButton')
            .mousePressed(() => {
                this.showCurrentValues = !this.showCurrentValues;
                this.verticalAlignmentDiv.style('visibility', this.showCurrentValues ? 'visible' : 'hidden')
                this.cvTitle.removeClass(this.showCurrentValues ? 'visButton' : 'visButtonActive');
                this.cvTitle.addClass(this.showCurrentValues ? 'visButtonActive' : 'visButton');
                // this.seeHide.html(this.showCurrentValues ? 'hide' : 'see');
            });

        this.cvDecription = this.p
            .createP()
            .parent(this.cvDiv)
            // .style('margin-top', '1.7em')
            .class('currentValueP');

        //
        // Current values
        //
        this.showCurrentValues = false;
        this.verticalAlignmentDiv = this.p
            .createDiv()
            .parent(this.cvDiv)
            .style("display", "inline-block")
            .style('visibility', this.showCurrentValues ? 'visible' : 'hidden');

        const numColumns = 3;
        const individualParamDivWidth =
            (this.d.cvWidth) / numColumns;
        // const individualParamDivWidthPx = `${individualParamDivWidth}px`;
        const offSet = 4;
        const smallWidthPx = `${individualParamDivWidth - offSet}px`;
        const largeWidthPx = `${individualParamDivWidth + 2 * offSet}px`;

        this.currentSLabel = this.p.createDiv()
            .class("labelDiv")
            .style("width", smallWidthPx)
            .parent(this.verticalAlignmentDiv);

        this.currentALabel = this.p.createDiv()
            .class("labelDiv")
            .style("width", smallWidthPx)
            .parent(this.verticalAlignmentDiv);

        this.currentQLabel = this.p.createDiv()
            .class("labelDiv")
            .style("width", largeWidthPx)
            .parent(this.verticalAlignmentDiv);
    
        this.currentRLabel = this.p.createDiv()
            .class("labelDiv")
            .style("width", smallWidthPx)
            .parent(this.verticalAlignmentDiv);

        this.nextSLabel = this.p.createDiv()
            .class("labelDiv")
            .style("width", smallWidthPx)
            .parent(this.verticalAlignmentDiv);

        this.nextQLabel = this.p.createDiv()
            .class("labelDiv")
            .style("width", largeWidthPx)
            .parent(this.verticalAlignmentDiv);
    }

    // this.p.createSpan("Hover over a grid cell to see the current estimates of action values ").parent(this.cvDecription);
    // this.descPh = this.p.createSpan("").parent(this.cvDecription).style('font-size', '1.1em');
    // this.d.kat.render("Q(s_i, \\cdot)", this.descPh.elt);
    // this.p.createSpan(", where ").parent(this.cvDecription);
    // this.descPh = this.p.createSpan("").parent(this.cvDecription).style('font-size', '1.1em');
    // this.d.kat.render("s_i", this.descPh.elt);
    // this.p.createSpan(" is the state associated with that cell. ").parent(this.cvDecription);

    // this.p.createSpan("To see or hide the current values of pseudo-code-related variables, press &nbsp;").parent(this.cvDecription);
    // this.p.createSpan("Press &nbsp;").parent(this.cvDecription);
    // this.buttonCvVis = this.p.createSpan("")
    //     .parent(this.cvDecription)
    //     .class(this.showCurrentValues ? 'visButtonActive' : 'visButton')
    //     .mousePressed(() => {
    //         this.showCurrentValues = !this.showCurrentValues;
    //         this.verticalAlignmentDiv.style('visibility', this.showCurrentValues ? 'visible' : 'hidden')
    //         this.buttonCvVis.class(this.showCurrentValues ? 'visButtonActive' : 'visButton');
    //         this.seeHide.html(this.showCurrentValues ? 'hide' : 'see');
    //     });
    // this.p.createSpan(" to ").parent(this.cvDecription);
    // this.seeHide = this.p.createSpan(this.showCurrentValues ? 'hide' : 'see').parent(this.cvDecription);
    // this.p.createSpan(" the current values of pseudo-code variables.").parent(this.cvDecription);

}
