/* eslint-disable one-var */
/* eslint-disable prettier/prettier */
/* eslint-disable import/no-named-as-default */
/* eslint no-param-reassign: "off", no-use-before-define: "off" */
import Gridworld from './gridworldExtended';
import QPolicy from './algoPolicy';
import vars from '../../global-styles/_myVariables.scss';
// import "katex/dist/katex.min.css"

export default function sketch(p) {
    const kat = require('katex');

    const parentDiv = p.createDiv().parent();
    const sWidth = parentDiv.offsetWidth;

    // DESIGN DICT d
    const d = {
        // Basic dimensions
        sketchWidth: 600,
        sketchHeight: 370,
        kat: kat
    };

    // Base lengths, layout
    d.margin = d.sketchWidth / 30;
    d.hMargin = d.margin / 2;
    d.hSketchWidthMinusMargin = d.hSketchWidth - d.margin;
    d.hSketchWidth = d.sketchWidth / 2;
    d.hSketchHeight = d.sketchHeight / 2;
    d.tSketchWidth = d.sketchWidth / 3;
    d.tSketchHeight = d.sketchHeight / 3;

    // Dimensions of main components
    d.algoWidth = d.tSketchWidth; //  - 1 * d.margin
    // d.algoHeight = d.tSketchWidth - 1 * d.margin;
    d.gwWidth = d.hSketchWidth + 3 * d.hMargin;
    d.gwHeight = d.gwWidth;

    d.lcWidth = d.tSketchWidth - 2 * d.margin;
    d.lcHeight = d.hSketchHeight;
    // d.lcHeightMinusMargin =  d.lcHeight - d.margin;
    d.cvWidth = d.algoWidth;
    d.algoParamWidth = d.lcWidth;

    // Gridworld
    d.numActions = 4;
    d.numColumns = 5;
    d.numRows = 5;
    d.numStates = d.numColumns * d.numRows;
    d.pcPositions = [9];
    d.mrPositions = [1, 2, 3, 4];
    d.cellSize = d.gwWidth / d.numColumns;
    d.innerCellMargin = d.cellSize / 10;
    d.emojiOffSetY = d.cellSize / 22;
    d.lcMaxReturn = 10;
    d.lcMinReturn = -10;

    // Positions of main elements
    d.xAgent = 0;
    d.yAgent = 0;
    d.xAgentAlgo = (d.sketchWidth * 2) / 3 + 2 * d.margin;
    d.yAgentAlgo = d.lcHeight + 2 * d.margin;
    d.xGw = 1;
    d.yGw = 2 * d.margin; // 1;
    d.xGwSet = d.xGw + d.hMargin; //  + d.hMargin
    d.yGwSet = d.yGw + d.gwHeight + d.margin / 2;
    d.yLc = 0;
    d.xLc = (d.sketchWidth * 2) / 3 + 2 * d.margin;
    // d.xCv = d.sketchWidth * 2 / 3 + 2 * d.margin;
    // d.yCv = d.lcHeight + 2 * d.margin;
    d.xCv = 0;
    d.yCv = d.gwHeight - (3 / 4) * d.margin;

    // Colors
    // d.bg = p.color(248);
    d.bg = p.color(vars.cBackgroundGrey);
    d.minColor = p.color(vars.cMinValLight);
    d.maxColor = p.color(vars.cMaxVal);

    // Text sizes & related  TODO: check with scss
    d.emojiSize = 40;
    d.valueSize = 11;
    d.rewardSize = 13;
    d.rewardOffSetX = (3 / 7) * d.cellSize;
    d.rewardOffSetY = (3 / 8) * d.cellSize;
    d.policyArrowSize = (d.cellSize * 4) / 10;
    d.arrowHeadSize = 4;
    d.axisTickSize = d.margin / 7;
    d.lcLeftPadding = d.axisTickSize * 8; // Of the axis lines... this is the space for axis labels.
    // d.lcTopPadding = 1.5 * d.margin;
    d.lcTopPadding = 52; // 1.5 * d.margin;
    d.lcBottomPadding = 36; // 1.5 * d.margin;
    d.lcHeightMinusPadding = d.lcHeight - d.lcTopPadding - d.lcBottomPadding;
    d.axisLabelSize = 11;
    d.axisTickLabelSize = -1;
    d.lcCircleWidth = 5;

    // Time
    d.maxFramesTillStep = 50;
    d.fadeFrames = 40;
    d.framesPlay = 5; // Frames of agent movement when playing continuously (has to be less than the number of lines in pseudo code until next arrives at that point)
    d.framesStill = 20; // Frames of agent movement when playing line by line.
    d.framesLearn = 28; // Frames of arrow/reward movement when playing continuously.
    d.framesLearnStill = 28; // Frames of arrow/reward movement when playing playing line by line.
    d.afterLandingFrames = 7; // How long should reward and arrow stay on their target after arriving?

    // Titles
    d.lcTitle = 'LEARNING PERFORMANCE'; //  "LEARNING CURVE";
    d.algoTitle = 'Q-LEARNING'; // PSEUDO CODE of
    d.algoParamTitle = 'ALGORITHM PARAMETERS';
    d.currentVariablesTitle = ' CURRENT VARIABLE VALUES';

    // Init main components
    let agent;
    let gw;
    let lc;
    let cv;

    let framesTillStep;
    let frameSinceLast = 0;

    // Profiling
    let currentFr = 0;
    // let currentDLUL = 0;

    // These game-flow parameters are shared across many components.
    const sharedParams = {
        play: false,
        playOnce: false
    };

    // // Place the sketch into the left margin if window is wide enough
    let cnv = p.createCanvas(0, 0);
    const sketchContainer = cnv.parent();
    sketchContainer.id = 'sketchContainerValue';
    const p5parent = p.select('#sketchContainerValue');
    p5parent.addClass('inlineSketchContainer');
    p5parent.style('position', 'relative');
    p5parent.style('width', `${d.sketchWidth}px`);
    p.setup = () => {
        cnv = p.createCanvas(d.sketchWidth, d.sketchHeight).id('valuecanvas'); // .parent(cnvContainer)
        p.clear();
        p.smooth();
        p.textFont('Lato');
        const titleDiv = p
            .createDiv()
            .style('width', `${d.sketchWidth}px`)
            .position(0, 0);
        p.createP('Current value estimates')
            .parent(titleDiv)
            .position(0, 0)
            .style('width', `${d.gwWidth}px`)
            .class('sketchSubTitle');
        gw = new Gridworld(p, d, sharedParams, undefined, 'q', '');
        agent = new QPolicy(p, d, sharedParams, gw, lc);
        agent.qTable = [
            [-2.4917238086382625, -67.232, -2.5842589508880143, -2.5655588864000003],
            [0, 0, 0, 0],
            [0, 0, 0, 0],
            [0, 0, 0, 0],
            [0, 0, 0, 0],
            [-1.9540939917312, -1.8212930922414081, -2.06, -1.9646944072171517],
            [-1.2843776, 1.22, -36, -1.38977032192],
            [-0.6, 2.3021440000000001, -20, -0.7379072],
            [-0.06400000000000002, 4.880000000000001, -36, -0.2],
            [0, 0, 0, 0],
            [-1.44858624, -1.486770688, -1.520923738112, -1.4],
            [-1.140416, -0.91625728, -1.1065216, -1.084224],
            [-0.6, 1.0291716096000003, -0.5920000000000001, -0.6416000000000001],
            [-0.2, 5.3492777984, -0.2, -0.472],
            [-0.279552, -0.2, 8.65782272, 1.101615104],
            [-1.093184, -1.1386880000000001, -1.363356672, -1],
            [-0.9331200000000001, -0.9264, -1.0397952, -0.8937600000000001],
            [-0.6, -0.8480000000000001, -0.7437440000000001, -0.6080000000000001],
            [-0.5680000000000001, -0.4736, -0.36000000000000004, -0.6320000000000001],
            [-0.6512, -0.4, 2.4447616000000005, -0.4],
            [-0.8, -0.84992, -1.036352, -1],
            [-0.6, -0.9075200000000001, -0.8, -0.768],
            [-0.8, -0.7456, -0.7984, -0.6400000000000001],
            [-0.4, -0.528, -0.56, -0.6],
            [-0.4, -0.6, -0.6304, -0.432]
        ];
        drawBackgroundAndGrid(false);
        gw.drawQagent(agent.qTable, true);
        // p.noLoop();
    };

    p.draw = () => {
        drawBackgroundAndGrid(false); // false
        gw.drawQagent(agent.qTable, true);
    };

    function drawBackgroundAndGrid(drawGrid) {
        p.background(255); // 209, 208, 166
        if (drawGrid) {
            p.push();
            p.fill(250);
            p.strokeWeight(1);
            p.rect(0, 0, d.sketchWidth, d.sketchHeight);
            p.line(d.sketchWidth / 2, 0, d.sketchWidth / 2, d.sketchHeight);
            p.line(0, d.sketchHeight / 2, d.sketchWidth, d.sketchHeight / 2);
            p.pop();
        }
    }
}
