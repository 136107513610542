import React from 'react';
// import { Link } from "gatsby"
import footerStyles from './footer.module.scss';

const Footer = () => {
    return (
        // className={footerStyles.navItem}
        <footer className={footerStyles.footer}>
            <p>© 2021</p>
            {/* Copyright © 2019 Jan Malte Lichtenberg */}
        </footer>
    );
};

export default Footer;
