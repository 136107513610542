import Layout from "../../../../src/components/blogLayout";
import Sketch from "../../../../src/components/sketch";
import { KatexBlock, KatexInline } from "../../../../src/components/Katex";
import { Link } from 'gatsby';
import DateConverter from "../../../../src/components/dateConverter";
import { SideNote, MarginNote } from "../../../../src/components/sideNote";
import dMDP from "../../../../src/scripts/diagrams/dMDP";
import NewThought from "../../../../src/components/newThought";
import sketchGw from "../../../../src/scripts/sGw/sPancakes";
import sketchGwMDP from "../../../../src/scripts/sGw/sPancakesMDP";
import sketchOptVal from "../../../../src/scripts/sOptVal/sOptVal";
import Commento from "../../../../src/components/commento";
import * as React from 'react';
export default {
  Layout,
  Sketch,
  KatexBlock,
  KatexInline,
  Link,
  DateConverter,
  SideNote,
  MarginNote,
  dMDP,
  NewThought,
  sketchGw,
  sketchGwMDP,
  sketchOptVal,
  Commento,
  React
};