import React, { Component } from 'react';
import styled from '@emotion/styled';
import p5 from 'p5';

const SketchWrapper = styled.div`
    & > canvas {
        /* position: absolute is only needed for putting a sketch into the background */
        /* position: absolute;  */
        display: block;
        /* margin: 0; */
        padding: 0 auto;
        top: 0;
        left: 0;
        margin: 0 auto;
        /* margin: 40; */
        /* // z-index is only needed for putting a sketch into the background */
        /* z-index: -2; */
    }
`;

// https://stackoverflow.com/questions/5581034/is-there-are-way-to-make-a-child-divs-width-wider-than-the-parent-div-using-css

class Sketch extends Component {
    constructor(props) {
        super(props);
        this.sketchRef = React.createRef();
        this.canvas = null;
    }

    componentDidMount() {
        this.canvas = new p5(this.props.sketch, this.sketchRef.current); //eslint-disable-line
    }

    componentDidUpdate() {
        this.canvas.remove();
        this.canvas = new p5(this.props.sketch, this.sketchRef.current); //eslint-disable-line
    }

    componentWillUnmount() {
        this.canvas.remove();
    }

    render() {
        return <SketchWrapper ref={this.sketchRef} />;
    }
}

export default Sketch;
