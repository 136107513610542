import React, { Component } from 'react';

const SideNote = props => {
    const snId = 'sn-' + props.snId;
    return (
        <>
            <label htmlFor={snId} className="margin-toggle sidenote-number">
            </label>
            <input type="checkbox" id={snId} className="margin-toggle" />
            <span className="sidenote">{props.children}</span>
        </>
    );
};

const MarginNote = props => {
    const mnId = 'mn-' + props.mnId;
    return (
        <>
            <label htmlFor={mnId} className="margin-toggle">
                &#8853;
            </label>
            <input type="checkbox" id={mnId} className="margin-toggle" />
            <span className="marginnote">{props.children}</span>
        </>
    );
};

export { SideNote, MarginNote };
