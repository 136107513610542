/* eslint-disable prettier/prettier */
export default class LearningCurve {
    constructor(p, d, sharedParams, showTitle = true, showMovingAverageButton = true) {
        this.p = p;
        this.d = d;
        this.sharedParams = sharedParams;
        this.lcWidth = this.d.lcWidth;
        this.lcPlotWidth = this.d.lcWidth - this.d.lcLeftPadding;
        this.minChunkLength = this.lcPlotWidth / 5;
        this.maxReturn = this.d.lcMaxReturn;
        this.minReturn = this.d.lcMinReturn;
        this.xAxisSpacing = 1; // Will get updated throughout...
        this.yAxisSpacing = 5;

        this.episodeReturns = [];
        this.numReturns = this.episodeReturns.length;
        this.maxNumberOfReturns = 100;
        this.currentReturn = 0;
        this.episodeCount = 0;
        this.monitoredEpisodes = [this.episodeCount];
        this.movingAverages = [];
        this.movingAverageN = 10;
        this.showMovingAverages = false;
        this.showMovingAverageButton = showMovingAverageButton;
        if (showTitle) {
            this.learningCurveTitle = this.p
                .createP(this.d.lcTitle)
                .position(this.d.xLc, this.d.yLc)
                .style('width', `${this.lcWidth}px`)
                .class('sketchSubTitle');
        }

        if (showMovingAverageButton) {
            // Moving Average
            const rightIndent = 110;
            this.buttonMovingAveragesDiv = this.p
                .createDiv()
                .class('visButtonDiv')
                .style('width', `${rightIndent}px`)
                .position(
                    this.d.xLc + this.d.lcWidth - rightIndent,
                    this.d.yLc + (1 / 2) * this.d.lcTopPadding
                );
            this.buttonMovingAverages = this.p
                .createSpan(' Moving average')
                .class(this.showMovingAverages ? 'visButtonActive' : 'visButton')
                .parent(this.buttonMovingAveragesDiv)
                .mousePressed(() => {
                    this.showMovingAverages = !this.showMovingAverages;
                    this.buttonMovingAverages.class(
                        this.showMovingAverages ? 'visButtonActive' : 'visButton'
                    );
                });
        }
    }

    reset() {
        this.episodeReturns = [];
        this.numReturns = this.episodeReturns.length;
        this.maxReturn = this.d.lcMaxReturn;
        this.minReturn = this.d.lcMinReturn;
        this.currentReturn = 0;
        this.episodeCount = 0;
        this.monitoredEpisodes = [this.episodeCount];
        this.movingAverages = [];
    }

    getAppropriateXaxisSpacing() {
        if (this.numReturns <= 10) {
            this.xAxisSpacing = 1;
        } else if (this.numReturns <= 20) {
            this.xAxisSpacing = 2;
        } else if (this.numReturns <= 50) {
            this.xAxisSpacing = 5;
        } else {
            this.xAxisSpacing = 10;
        }
    }

    getAppropriateYaxisSpacing() {
        if (this.minReturn >= -20) {
            this.yAxisSpacing = 5;
        } else if (this.minReturn >= -40) {
            this.yAxisSpacing = 10;
        } else {
            this.yAxisSpacing = 20;
        }
    }

    pushReturn(ret) {
        let nTmp = Math.min(this.numReturns, this.movingAverageN);
        let maTmp;
        if (this.movingAverages.length > 0) {
            maTmp = this.movingAverages[this.movingAverages.length - 1] * nTmp + ret;
        } else {
            maTmp = ret;
        }
        if (this.numReturns >= this.movingAverageN) {
            maTmp -= this.episodeReturns[this.numReturns - this.movingAverageN];
        } else {
            nTmp += 1;
        }
        this.movingAverages.push(maTmp / nTmp);
        this.episodeReturns.push(ret);
        this.numReturns = this.episodeReturns.length;
        this.episodeCount += 1;
        this.monitoredEpisodes.push(this.episodeCount);

        if (this.numReturns > this.maxNumberOfReturns) {
            this.episodeReturns.shift();
            this.monitoredEpisodes.shift();
            this.movingAverages.shift();
            this.numReturns = this.episodeReturns.length;
        }

        this.getAppropriateXaxisSpacing();
        this.getAppropriateYaxisSpacing();
    }

    updateReturn(currentReturn) {
        this.currentReturn = currentReturn;
        this.getAppropriateYaxisSpacing();
    }

    drawReturnPerEpisode() {
        this.p.push();
        this.p.strokeWeight(0.8);
        this.p.textAlign(this.p.CENTER, this.p.CENTER);
        this.p.textSize(this.d.axisLabelSize);
        this.p.translate(this.d.xLc + this.d.lcLeftPadding, this.d.yLc + this.d.lcTopPadding);
        // Y-axis
        this.p.push();
        this.p.translate(-this.d.axisTickSize, 0);
        this.p.line(0, 0, 0, this.d.lcHeightMinusPadding);
        this.p.line(-this.d.axisTickSize, 0, 0, 0);
        this.p.line(
            -this.d.axisTickSize,
            this.d.lcHeightMinusPadding,
            0,
            this.d.lcHeightMinusPadding
        );
        this.p.push(); // Return label
        this.p.translate(-this.d.axisTickSize * 4.5, this.d.lcHeightMinusPadding / 2);
        this.p.rotate(-this.p.PI / 2);
        this.p.textAlign(this.p.CENTER, this.p.BOTTOM);
        this.p.text('Return', 0, 0);
        this.p.pop();
        this.p.pop();

        this.p.push();
        let chunkLength = this.lcPlotWidth;
        this.p.stroke(160);
        if (this.numReturns > 0) {
            this.maxReturn = Math.max(this.currentReturn, this.maxReturn); // ...this.episodeReturns,
            this.minReturn = Math.min(this.currentReturn, this.minReturn); // ...this.episodeReturns
            chunkLength = Math.min(this.lcPlotWidth / this.numReturns, this.minChunkLength);
            let x = 0;
            let y = this.p.map(
                this.episodeReturns[0],
                this.minReturn,
                this.maxReturn,
                this.d.lcHeightMinusPadding,
                0
            );
            let maY = this.p.map(
                this.movingAverages[0],
                this.minReturn,
                this.maxReturn,
                this.d.lcHeightMinusPadding,
                0
            );
            let yPrev, xPrev, maYprev;
            if (this.numReturns > 1) {
                for (let rPrevIx = 0; rPrevIx < this.numReturns - 1; rPrevIx += 1) {
                    const rIx = rPrevIx + 1;
                    xPrev = x;
                    x = rIx * chunkLength;
                    yPrev = y;
                    y = this.p.map(
                        this.episodeReturns[rIx],
                        this.minReturn,
                        this.maxReturn,
                        this.d.lcHeightMinusPadding,
                        0
                    );
                    this.p.line(xPrev, yPrev, x, y);

                    if (this.showMovingAverages) {
                        // Moving average
                        maYprev = maY;
                        maY = this.p.map(
                            this.movingAverages[rIx],
                            this.minReturn,
                            this.maxReturn,
                            this.d.lcHeightMinusPadding,
                            0
                        );
                        this.p.stroke(0);
                        this.p.line(xPrev, maYprev, x, maY);
                        this.p.stroke(180);
                    }
                }
            } else {
                x = 0;
                y = this.p.map(
                    this.episodeReturns[0],
                    this.minReturn,
                    this.maxReturn,
                    this.d.lcHeightMinusPadding,
                    0
                );
                // maY = this.p.map(this.movingAverages[0], this.minReturn, this.maxReturn, this.d.lcHeightMinusPadding, 0);
            }
            const yCurrent = this.p.map(
                this.currentReturn,
                this.minReturn,
                this.maxReturn,
                this.d.lcHeightMinusPadding,
                0
            );
            const xCurrent = this.numReturns * chunkLength;
            this.p.line(x, y, xCurrent, yCurrent);
            this.p.circle(xCurrent, yCurrent, this.d.lcCircleWidth);
        } else {
            this.maxReturn = Math.max(this.currentReturn, this.maxReturn);
            this.minReturn = Math.min(this.currentReturn, this.minReturn);
            const yCurrent = this.p.map(
                this.currentReturn,
                this.minReturn,
                this.maxReturn,
                this.d.lcHeightMinusPadding,
                0
            );
            const xCurrent = 0;
            this.p.circle(xCurrent, yCurrent, this.d.lcCircleWidth);
        }
        this.p.pop();

        // Y-axis TICK LABELS
        this.p.push();
        this.p.textSize(this.d.axisTickLabelSize);
        this.p.translate(-2.5 * this.d.axisTickSize, 0);
        this.p.textAlign(this.p.RIGHT, this.p.CENTER);
        // this.p.text(this.maxReturn, 0, 0);
        this.p.text(this.minReturn, 0, this.d.lcHeightMinusPadding);
        // const zeroPos = this.p.map(0, this.minReturn, this.maxReturn, this.d.lcHeightMinusPadding, 0);
        // this.p.text(0, 0, zeroPos);
        for (
            let yIx = this.maxReturn;
            yIx > this.minReturn + 0.33 * this.yAxisSpacing;
            yIx -= this.yAxisSpacing
        ) {
            const yPos = this.p.map(
                yIx,
                this.minReturn,
                this.maxReturn,
                this.d.lcHeightMinusPadding,
                0
            );
            // const rc = rIx * chunkLength;
            // this.p.line(rc, 0, rc, this.d.axisTickSize);
            this.p.text(yIx, 0, yPos);
            this.p.line(
                this.d.axisTickSize / 2,
                yPos,
                this.d.axisTickSize / 2 + this.d.axisTickSize,
                yPos
            );
        }

        // this.p.line(this.d.axisTickSize / 2, zeroPos, this.d.axisTickSize / 2 + this.d.axisTickSize, zeroPos)
        this.p.pop();

        // X-axis
        this.p.push();
        this.p.textAlign(this.p.CENTER, this.p.TOP);
        this.p.textSize(this.d.axisLabelSize);
        this.p.translate(0, this.d.lcHeightMinusPadding + this.d.axisTickSize);
        this.p.text('Episode', this.lcPlotWidth / 2, this.d.axisTickSize * 4);
        const yLabel = 1.5 * this.d.axisTickSize;
        this.p.textSize(this.d.axisTickLabelSize);
        this.p.line(0, 0, this.lcPlotWidth, 0); // x-axis
        for (
            let rIx = 0;
            rIx < this.numReturns - 0.33 * this.xAxisSpacing;
            rIx += this.xAxisSpacing
        ) {
            const rc = rIx * chunkLength;
            this.p.line(rc, 0, rc, this.d.axisTickSize);
            this.p.text(this.monitoredEpisodes[rIx], rc, yLabel);
        }
        const rIx = this.numReturns;
        const rc = rIx * chunkLength;
        this.p.text(this.monitoredEpisodes[rIx], rc, yLabel);
        this.p.line(rc, 0, rc, this.d.axisTickSize);
        this.p.pop();

        this.p.pop();
    }
}
